.navbar {
  background: white;
  box-shadow: 0px 10px 40px #31394629;
  border-radius: 6px;
  padding: 10px 20px;
  display: flex;
  justify-content: right;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  position: fixed;
  margin: 2%;
  width: 93%;
}

.nav-link {
  cursor: pointer;
  margin-right: 20px;
  font-size: 20px;
}

.demo-button {
  width: 150px;
  height: 50px;
  background: var(--tq1) 0% 0% no-repeat padding-box;
  background: #4fc2c9 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: none;
  color: var(--white);
  text-align: center;
  font: normal normal normal 18px/20px Zoho Puvi;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
}

.demo-button:hover {
  background: #45b4ba;
}

.nav-name {
  display: flex;
  flex-direction: row;
  font-family: Zoho Puvi;
}

.dataeazeIcon {
  background: url("../../assets/banereazeIcon.jpg");
}

@media (max-width: 768px) {
  .navbar {
    padding: 10px 15px;
    justify-content: center;
  }

  .nav-link {
    margin-right: 10px;
  }

  .demo-button {
    width: 120px;
    height: 40px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .navbar {
    padding: 10px;
  }

  .nav-link {
    margin-right: 5px;
  }

  .demo-button {
    width: 100px;
    height: 35px;
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .navbar {
    justify-content: center;
    padding: 18px 8px !important;
    top: 2%;
    img {
      left: 4%;
      height: 35%;
    }
  }

  .nav-link {
    margin-right: 10px;
    padding: 15px;
    border-bottom: 1px solid #4fc2c952;
    width: 100%;
  }
  .breadcrumb-toggle {
    background: white;
    outline: 0;
    border: 0px;
    font-size: 25px;
    color: #b0b8c3;
    position: absolute;
    right: 14px;
  }
  .demo-button {
    font-size: 14px;
    position: relative;
    left: 21%;
    width: 105px;
    height: 38px;
    font-size: 14px;
  }

  .sidebar {
    position: fixed;
    top: 0;
    width: 150px;
    height: auto;
    background-color: whitesmoke;
    transition: left 0.3s ease;
    padding: 0px;
    box-shadow: 0px -1px 13px 4px #f9f2f2a1;
  }

  .sidebar.open {
    right: 5%;
    top: 4%;
  }

  .close-sidebar {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: #fff;
    font-size: 20px;
  }
  .nav-name {
    flex-direction: column;
    overflow-x: hidden;
    color: #4fc2c9;
  }
}