
.login-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background: #FFFFFF;
  width: 100vw;
}

.left-container {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  gap:10px;
  margin-left: 4%;
  /* padding: 20px; */
  flex-direction: column;
  width: 50vw;
  height: 100vh;
  color: #FFFFFF;
}

.right-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  width: 50vw;
  height: 100vh;
  /* padding: 20px; */
  /* margin-left: 200px; */
  background: #F0F3F6;
  text-align: center;
}

.sign-up {
  width: 25vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  /* height: 50px; */
  border-radius: 6px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 20px;
  color: var(--unnamed-color-0c111b);
  color: #0C111B;
}

.complieaze-logo-login{
  letter-spacing: 0;
    width: 100%;
    align-self: self-start;
    opacity: 1;
    text-align: left;
    /* margin-left: 72px; */
    color: #4fc2c9;
   font-size: 50px;
   font-weight: 400;
   font-family: 'Manrope', sans-serif;
    height: 27px;
    padding: 20px 24px 26px 0px;
    z-index: 2;
}
.input-label {
 /* max-width: 100%; */
height: 21px;
color: var(--unnamed-color-0c111b);
text-align: left;
letter-spacing: 0px;
color: #0C111B;
opacity: 1;
margin-bottom: 10px;
margin-top: 10px;
font-family: 'Manrope', sans-serif;
}

.email-input,
.password-input {
  /* max-width:  100%; */
height: 30px;
  padding: 15px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
border: 1px solid var(--unnamed-color-b0b8c3);
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #B0B8C3;
opacity: 1;
  border-radius: 4px;
  opacity: 1;
  color: #181717;
  text-align: left;
  letter-spacing: 0px;
  margin-bottom: 15px;
  font-family: 'Manrope', sans-serif;
  
}
.email-input,
.password-input {
  font-size: 20px; 
}
.email-input::placeholder,
.password-input::placeholder {
  color: #777C83;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  /* text-indent: 0px; */
  font-size: 14px;
}
.password-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}

.signup-link{
  /* color: #777C83; */
  text-decoration: none;
  align-self: flex-start;
  justify-self: left;
  color: var(--unnamed-color-1b88e6);
text-align: left;
font:normal normal 600 16px/21px "Red Hat Display",sans-serif;
letter-spacing: 0px;
color: #1B88E6;
opacity: 1;
}
.reset-password-link {
  /* color: #777C83; */
  text-decoration: none;
  align-self: flex-end;
  justify-self: right;
  margin-left: 170px;
  color: var(--unnamed-color-1b88e6);
  text-align: left;
  font: normal normal 600 16px/21px "Red Hat Display",sans-serif;
  letter-spacing: 0px;
  color: #1B88E6;
  opacity: 1;
}

.signup-link:hover,
.reset-password-link:hover {
  text-decoration: underline;
}
  .title {
    /* position: absolute;
    top: 239px;
    left: 86px; */
    
    align-self: left;
    justify-self: left;
    width: 100%;
    height: auto;
    color: #191717;
    text-align: left;
    font-size: 30px;
    font-weight: 400;
    font-family: 'Manrope', sans-serif;
    letter-spacing: 0px;
    opacity: 1;
    /* margin-left: 70px; */
    /* margin-top: 60px; */
  }
  .subtitle {
    /* position: absolute;
    top: 400px;
    left: 90px; */
    /* width: 482px; */
    height: 52px;
    /* margin-left: 70px; */
    /* margin-top: 30px; */
    color: #777C83;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
  }
/* .password-input::placeholder{
    color: #777C83;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    text-indent: 10px; 
  }   




.email-input::placeholder {
    color: #777C83;
    text-align: left;
    letter-spacing: 0px;
    opacity: 1;
    text-indent: 10px; 
  } */

  
  /* .submit-button {
    align-self: flex-start;
    justify-self: center;
    width: 80px;
    height: 35px;
    margin-top: 20px;
    background: transparent linear-gradient(270deg, var(--unnamed-color-1b88e6) 0%, #1778CB 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(270deg, #1B88E6 0%, #1778CB 100%) 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: var(--unnamed-color-ffffff);
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
text-decoration: none;
  } */


.button-container {
  display: flex;
  align-items: center; 
  justify-content: space-between; 
  width: 27vw;
  /* gap: 50px; */
}


.submit-container {
  margin-right: 10px; 
  margin-top: 5px;
  width: 12vw;
}

.submit-buttonn {
  /* width: 80px; */
  height: 41px;
  background-color: #3F9BA1;
  border: none;
  border-radius: 4px;
  color: #ffffff !important;
  font-size: 16px;
  /* cursor: pointer; */
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-container-content{
  width: 40vw;
  height: 35vh;
  color: #fff;
  display: flex;
  /* flex: 1 1; */
  flex-direction: column;
  gap: 10px;
  gap: 10px;
}
.google-login-container {
  margin-left: 30px;
  margin-top: 5px;
  width: 12vw;
}

.google-login-button {
  /* width: 120px; */
  width: 12vw;
  height: 41px;
  background-color: #3F9BA1;
  border: none;
  border-radius: 4px;
  color: #ffffff !important;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.google-login-button img {
  margin-right: 22px;
  width: 25%;
  height: 100%;
  /* border-radius: 50%; */
}

.google-login-container:hover,
.google-login-container:active,
.google-login-container:focus {
  text-decoration: none;
  outline: none;
}
.footer-container {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  padding: 15px 15px;
  font-family: 'Manrope', sans-serif;
}

.footer-link {
  color: #3F9BA1;
  text-decoration: none;
  font-size: 14px;
}

.footer-separator {
  margin: 0 10px;
  color: #000;
}

.footer-text {
  font-size: 14px;
  color: #000;
}

.footer-image {
  margin-left: 6px;
  width: 65px;
  height: 22px;
}

.email-input,
.password-input,
.submit-button {
  cursor: not-allowed; 
  pointer-events: none; 
  opacity: 0.6; 
}

.email-input:disabled,
.password-input:disabled,
.submit-button:disabled {
  cursor: not-allowed !important; 
  pointer-events: none;
  opacity: 0.6;
}


.disabled-input:disabled {
  pointer-events: none; 
  opacity: 0.6; 
}
