/* Table container styles */
.audit-table-container {
  width: 100%;
  margin: 0 auto;
}

/* Sticky table headers */
.MuiTableHead-root th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
  text-align: center;
}

/* Table rows and cells */
.MuiTableBody-root tr:hover {
  background-color: #f9f9f9;
}

.MuiTableCell-root {
  text-align: center;
  padding: 12px;
  font-size: 14px;
  color: #555;
}

/* Table pagination styles */
.MuiTablePagination-root {
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
}

/* Header styles for better appearance */
.audit-top-users-table thead th {
  background-color: #eceff1;
  font-weight: bold;
  color: #212121;
}

/* Add custom margin for container */
.audit-top-five-users {
  margin: 16px;
  padding: 16px;
}

.top-5-user h2 {
  margin-bottom: 2rem;
  font-size: 18px;
  text-align: center;
  color: #333;
}