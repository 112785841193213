.audit-command-usage,
.audit-regulator-usage,
.command-header {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  height: 95%;
  display: flex;
  flex-direction: column;
}

.command-header {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between elements */
  width: 100%;
  position: relative; /* For absolute positioning if needed */
  margin-bottom: 1rem; /* Adds space below the header */
}

/* Left Placeholder Styling */
.header-placeholder {
  width: 24px; /* Width equal to the download icon's width */
}

/* Centered Title Styling */
.command-header h2 {
  margin: 0;
  font-size: 18px;
  text-align: center;
  color: #333;
  flex-grow: 1; 
  position: absolute; 
  left: 50%;
  transform: translateX(-50%);
}

/* Download Icon Styling */
.download-icon {
  cursor: pointer;
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  top: -23px; 
  right: 10px;
}

.download-icon:hover {
  transform: scale(1.1);
}

.audit-chart-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audit-custom-tooltip {
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.audit-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid #f0f0f0;
}

/* Legend Item Styling */
.audit-legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.audit-legend-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .audit-legend {
    flex-direction: column;
    align-items: center;
  }

  .command-header h2 {
    font-size: 16px;
  }
}

/* Hover effects */
.audit-legend-item:hover {
  opacity: 0.7;
  cursor: pointer;
}

/* Pie chart segment hover state */
.recharts-sector:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease;
}
