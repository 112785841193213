.footer {
    background-color: #f7faff;
    padding: 10px 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Zoho Puvi;
    position: fixed;
    z-index: 2;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .bottom-left-image {
    width: 70px; 
    height: 16px; 
    opacity: 1;
    cursor: pointer;
    margin-left: 6px;
    margin-bottom: -4px;
    
    }
  .left-content {
    font-size: 14px;
    color: #000;
  }
 .aws{
  height: 36px;
  width: 110px;
}
  .right-content {
    font-size: 14px;
    color: #000;
    span {
      text-decoration: none;
      color: #000;
      margin: 0 10px;
    }
  }
 
@media (max-width: 768px) {
  .footer {
    position: absolute;
    bottom: 0;
    top: 766%;
    flex-direction: column;
  }

  .left-content,
  .right-content {
    text-align: center;
    text-wrap: balance;
  }
}