.home-section {
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 770px;
  background: transparent url("../../assets/homeBackground.jpg") 100% 16%
    no-repeat padding-box;
  opacity: 1;
  margin-top: -3%;
}
.LandingPage {
  overflow-x: hidden;
}
.homeText {
  font-family: Zoho Puvi, Bold;
  color: var(--white);
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  top: 33%;
  left: 6%;
  width: 41%;
  position: relative;
  font-size: 50px;
}
.cardView {
  display: flex;
  flex-direction: row;
}
.content{
  color: #102841;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}
.demo-buttonApp {
  top: 141px;
  right: 83%;
  background: var(--tq1) 0% 0% no-repeat padding-box;
  background: #4fc2c9 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: none;
  color: var(--white);
  text-align: center;
  font: normal normal normal 18px/20px Zoho Puvi;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
  position: absolute;
  height: 41px;
  width: 17%;
}
.demo-buttonApp:hover {
  background: #45b4ba;
}
.cardView {
  width: 28%;
  height: 112px;
  border-radius: 20px;
  top: 347px;
  position: relative;
  flex-direction: column;
  background-color: rgb(255 255 255 / 14%);
  color: white;
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  font-family: Zoho Puvi;
  padding: 1%;
}
.cardProperty {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 6%;
  padding-right: 6%;
}
.lightText {
  color: #4fc2c9;
}
.cardDarktext {
  text-align: left;
  letter-spacing: 1px;
  color: #ffffffcf;
  font-size: 21px;
  inline-size: 362px;
  font-family: Zoho Puvi;
}
.featureHead {
  font-family: Zoho Puvi, Bold;
  color: var(--black);
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  color: #102841;
  display: flex;
  justify-content: center;
  padding: 3%;
  display: flex;
  flex-direction: column;
}
.featureHead span {
  display: block;
  line-height: 1;
  padding-left: 6px;
}
.docflex {
  display: flex !important;
  justify-content: center;
}
.complexColor {
  color: #4fc2c9;
}

.featureCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Zoho Puvi;
}
.lineH {
  line-height: 0.5;
}
.singleCardContainer {
  font-family: Zoho Puvi;
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
  padding: 0 10%;
  flex-direction: row;
  margin-top: 5%;
}
.singleCardContainerr {
  font-family: Zoho Puvi;
  display: flex;
  justify-content: space-around;
  margin-top: 5%;
  padding: 0 10%;
  flex-direction: row;
  margin-top: 5%;
}

.singleCardContainer img {
  height: 35px;
  width: 35px;
}
.singleCardContainerr img {
  height: 150px;
  width: 150px;
}
.cardText {
  text-align: left;
  word-wrap: break-word;
  display: block;
  width: 90%;
}
.cardWrapper {
  box-shadow: 0px 10px 40px #31394629;
  padding: 28px;
  /* text-align: center; */
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  background-color: white;
  z-index: 1;
  font-family: Zoho Puvi;
}
.cardWrapperr {
  /* box-shadow: 0px 10px 40px ; */
  padding: 30px;
  text-align: center;
  display: flex;
  width: 100%;
  margin: 20px;
  flex-direction: row;
  border-radius: 6px;
  background-color: #102841;
  z-index: 1;
  font-family: Zoho Puvi;
}
.custom-card {
  flex: 1;
  box-shadow: 0px 10px 40px;
  /* margin-right: 20px;  */
  /* height: 250px; */
  width: 300px;
  /* border: 1px solid; */
  border-radius: 3px;
  padding: 10px;
  margin: 10px;
  /* margin-right: 0; */
  background-color: #f7faff;
}
.custom-cardd {
  flex: 1;
  /* margin-right: 20px;  */
  /* height: 250px; */
  width: 300px;
  /* border: 1px solid;
  padding: 10px;
  margin: 10px; */
  /* margin-right: 0; */
  background-color: #f7faff;
}
.backgroundCurve {
  background-color: rgb(16, 40, 65);
  border-radius: 0px 0px 190px 0px;
  color: white;
  font-size: 32px;
  height: 500px;
  position: relative;
  margin-top: -13%;
  z-index: 0;
  position: relative;
  font-family: Zoho Puvi;
}
.demo-buttonFeature {
  top: 38%;
  left: 77%;
  background: #4fc2c9 0% 0% no-repeat padding-box;
  border-radius: 6px;
  border: none;
  text-align: center;
  font: normal normal normal 18px/20px Zoho Puvi;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  cursor: pointer;
  position: relative;
  height: 36px;
  width: 8%;
  &:hover {
    background: #45b4ba;
  }
}
.nextText {
  position: relative;
  top: 53%;
  left: 14%;
  font-size: 50px;
  font-weight: bolder;
}

.card-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-top: 7%;
  margin-left: 43%;
}

.contentWrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature-card {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 17px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s;
  background: #ffffff;
  margin: 3px;
  height: 24px;
  width: 388px;
  text-align: left;
  font-size: 17px;
  font-family: Zoho Puvi;
  font-weight: bold;
  border: 1px solid #eaeff7;
  cursor: pointer;
  z-index: 1;
}

.feature-card:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.feature-card.active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 20px;
  height: 160px;
  font-weight: 400;
  font-size: 17px;
}
.feature-card.active h2 {
  color: #4fc2c9;
}

.card-image {
  margin-top: 7%;
  left: 15%;
  /* bottom: -315%; */
  position: absolute;
}

.card-image img {
  width: 600px;
  height: 350px;
}

.card-content {
  flex: 1;
  text-align: left;
}

.card-content h2 {
  font-size: 20px;
}
.pricingBackgroundcurve {
  background-color: #4fc2c9;
  border-radius: 190px 0px 0px 0px;
  color: #102841;
  height: 400px;
  position: relative;
  margin-top: 8%;
  z-index: 0;
  font-family: Zoho Puvi;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
}

.pricing-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  margin-top: -11%;
  z-index: 1;
  position: relative;
  font-family: Zoho Puvi;
}

.pricing-card {
  background: var(--white) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 20px;
  opacity: 1;
  padding: 20px;
  text-align: center;
  margin: 10px;
  height: 300px;
  width: 250px;
}

.pricing-card h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.pricing-card p {
  font-size: 16px;
  margin: 10px 0;
}

.header-line {
  border: 1px solid #cbedef;
  width: 95%;
  margin: 20px auto;
}

.hrLine-below {
  border: 1px solid rgb(243, 245, 250);
  width: 80%;
  margin: 20px auto;
}

.contact-form {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  padding: 20px;
  margin-bottom: 65px;
}

form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 500px;
  padding: 54px;
}

.form-input {
  width: calc(50% - 10px);
  margin-bottom: 10px;
}

.form-input.full-width {
  width: 100%;
}

/* input,
textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: 0;
} */

.submit-text {
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin: 22px -5px;
}

.submit-button {
  background: var(--tq1) 0% 0% no-repeat padding-box;
  background: #4fc2c9 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 5px;
  color: var(--white);
  text-align: center;
  /* font: normal normal normal 18px/20px Zoho Puvi; */
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  margin-top: 4px;
}

.submit-button:hover {
  background: #45b4ba;
}
.flip-cards-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  background-color: #102841;
  padding: 30px;
  border-radius: 100px 0 0 0;
}

.flip-card {
  width: 300px;
  height: 300px;
  perspective: 1000px;
  margin: 0 20px;
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.flip-card-front {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.flip-card-back {
  transform: rotateY(180deg);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #81bea9;
}

.flip-card img {
  width: 50%;
  max-height: 150px;
  object-fit: cover;
}

.flip-card h3 {
  margin-top: 25px;
  text-align: center;
  font-family: "Zoho Puvi";
  font-size: 28px;
}

.flip-card p {
  text-align: center;
  font-size: 25px;
  padding: 10px;
  font-family: "Zoho Puvi";
}

body::-webkit-scrollbar {
  width: 0em;
}

body::-webkit-scrollbar-thumb {
  background-color: #f7faff;
}

.submit-text input[type="checkbox"] {
  width: 4%;
  position: relative;
  vertical-align: text-top;
  top: 2px;
  right: -1px;
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .card-image {
    left: 11%;
  }
  .card-image img {
    width: 400px;
    height: 300px;
  }
  .card-container {
    margin-left: 49%;
  }
  .homeText {
    width: 64%;
  }
  .cardView {
    width: 30%;
    top: 405px;
  }
  .cardDarktext {
    font-size: 16px;
    inline-size: 284px;
  }
  .cardWrapper {
    width: 100%;
  }
  .demo-buttonFeature {
    height: 46px;
    width: 10%;
  }
  .custom-card {
    margin: 5px;
    padding: 3px;
  }
}

/* Larger than large desktop */
@media screen and (min-width: 1201px) and (max-width: 1439px) {
  /* .custom-card{
    margin: 5px;
    padding: 6px;
  } */
  .card-image {
    left: 7%;
  }
  .cardView {
    width: 32%;
    margin-right: 19px;
  }
  .card-container {
    /* margin-top: 2%; */
    margin-left: 58%;
  }
  .demo-buttonApp {
    top: 185px;
  }
}

/* Larger than extra-large desktop */
@media screen and (min-width: 1440px) and (max-width: 1640px) {
  .card-image {
    left: 10%;
  }
  .home-section {
    background: transparent url("../../assets/homeBackground.jpg") 100% 116%
      no-repeat padding-box;
  }
  .demo-buttonApp {
    top: 187px;
  }
}
@media  screen and (min-width: 1440px) and (max-width:2549px) {
  
  
  .home-section{
    background: transparent url("../../assets/homeBackground.jpg") 100% 116% no-repeat
    padding-box; 
  }
  .cardWrapper{
    padding: 57px 43px;
  }
}

/* Larger than 4k resolution */
@media only screen and (min-width: 2550px) {
  .homeText {
    width: 25%;
  }

  .home-section {
    background: transparent url("../../assets/homeBackground.jpg") 100% 89%;
    height: 1077px;
  }
  .cardProperty {
    margin-top: 11%;
  }
  .card-container {
    /* margin-top: 2%; */
    margin-left: 27%;
  }
  .card-image {
    left: 25%;
  }
  .backgroundCurve {
    margin-top: -7%;
  }
  .pricing-cards {
    margin-top: -7%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .homeText {
    width: 100%;
    top: 24%;
  }
  .cardDarktext {
    font-size: 21px;
    inline-size: 200px;
  }
  .cardView {
    width: 30%;
    height: 170px;
  }
  .demo-buttonFeature {
    height: 38px;
    width: 14%;
    left: 37%;
    top: 58%;
  }
  .backgroundCurve {
    margin-top: -33%;
  }

  .flip-card {
    height: 185px;
    img {
      width: 40%;
    }
    h3 {
      font-size: 18px;
    }
    p {
      font-size: 23px;
    }
  }
  .card-container {
    margin-left: 40%;
  }
  .card-image img {
    width: 271px;
    height: 272px;
  }
  .card-image {
    margin-top: 7%;
    left: 5%;
    position: absolute;
  }
  .pricing-cards {
    margin-top: -22%;
  }
  .complexColor {
    padding: 10px;
  }
}
