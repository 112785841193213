.audit-regulator-usage {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  height: 95%;
  display: flex;
  flex-direction: column;
}

.audit-regulator-usage h2 {
  margin-bottom: 1rem;
  font-size: 18px;
  text-align: center;
  color: #333;
}

.audit-chart-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audit-custom-tooltip {
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.audit-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 0.5rem;
  border-top: 1px solid #f0f0f0;
}

.audit-legend-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}
.audit-legend-color {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .audit-legend {
    flex-direction: column;
    align-items: center;
  }
}

/* Hover effects */
.audit-legend-item:hover {
  opacity: 0.7;
  cursor: pointer;
}

/* Pie chart segment hover state */
.recharts-sector:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease;
}
