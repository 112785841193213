.audit-log-detailed-logs-table {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  height: 95%;
  width: 100%;
}

.audit-log-detailed-logs-table h2 {
  margin-bottom: 1rem;
  font-size: 18px;
}

.audit-log-detailed-logs-table-table {
  width: 100%;
  border-collapse: collapse;
}

.audit-log-detailed-logs-table th,
.audit-log-detailed-logs-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.audit-log-detailed-logs-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}



/* DetailedLogsTable.css */

.details-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.details-item {
  display: flex;
  margin-bottom: 4px;
}

.details-label {
  font-weight: bold;
  margin-right: 5px;
}

.details-value {
  /* Additional styling can be added here if needed */
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
