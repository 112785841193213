
.rbac-container {
  display: flex;
flex-direction: column;
height: 100vh;
background-color:#fff ;
width: 100vw;
pointer-events: auto;
}
.complieaze-header{
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 8vh;
  width: 100vw;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: var(--gray, #E6EBF0);
}
.admin-complieaze-logo {
background: #fff;
border: 1px solid #e6ebf0;
border-color: var(--gray, #e6ebf0);
display: flex;
flex-direction: column;
padding-left: 28px;
width: 15.9vw;
}
.sidebar {
  width: 15.9vw;
padding: 16px;
position: relative;
/* border-radius: 6px; */
background: #fff;
display: flex;
flex-direction: column;
/* margin-top: 70px;
margin-bottom: 50px;
margin-left: 70px; */
overflow-y: hidden;
border-width: 0px 1px 0px 0px; 
border-style: solid;
border-color: var(--gray, #E6EBF0);
}

.sidebar-header {
  margin-bottom: 40px;
}

.sidebar-header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.sidebar-header p {
  font-size: 12px;
  color: #888;
}

.sidebar-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sidebar-button {
  padding: 10px 15px;
  background-color:transparent;
  border: none;
  color: #333;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.token{
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 300px;  */
  max-width: 50%;
  height: 50px;
  border: 1px solid #ccc; 
  border-radius: 5px;
  background-color: #f9f9f9; 
  font-size: 1.2em; 
  font-family: 'Manrope', sans-serif; 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px;
  margin-top: 10px;
}
.sidebar-button:hover {
  background-color: #d6dde5;
}
.sidebar-button.active {
  background-color: #E6EBF0; 
  font-weight: bold;
  /* box-shadow: inset 0px 0px 5px #00000055;  */
}

.main-container {
  flex-grow: 1;
  display: flex;
  width: 80vw;
  flex-direction: column;
  background-color: #e6ebf0;
  padding: 20px;
}
.admin-table-container{
  background-color: #fff;
  height: 75vh;
  padding: 10px;
  overflow: auto;
}
.admin-container{
  background-color: #fff;
  height: 80vh;
  /* overflow: auto; */
}
.admin-body-container{
  display: flex;
  height: 92vh;
  width: 100%;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.admin-loader-container{
  position: absolute;
  z-index: 2;
  top: 50vh;
  left: 50vw;
}
.admin-role{
  border: 1px solid #ccc !important;
  border-radius: 5px o !important;
  font-size: 14px !important;
  height: 25px !important;
  margin-bottom: 13px !important;
  padding: 8px !important;
  resize: vertical;
  font-family: Manrope;
  width: calc(100% - 8px) !important;
}
.admin-description{
  border: 1px solid #ccc !important;
  border-radius: 5px o !important;
  font-size: 14px !important;
  margin-bottom: 13px !important;
  padding: 8px !important;
  height: 25px !important;
  resize: vertical;
  font-family: Manrope;
  width: calc(100% - 8px) !important;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
  gap: 10px; 
  align-items: center; 
}

.header-title {
  font-size: 24px;
  font-weight: 600;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.generate-token-btn {
  padding: 8px 12px;
  background-color: #008c96;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.generate-token-btn:hover {
  background-color: #006d72;
}

.profile-icon {
  font-size: 24px;
  cursor: pointer;
}

.content {
  padding-top: 20px;
}

h3 {
  font-weight: 500;
}

table {
width: 100%;
border-collapse: collapse;
}

th, td {
padding: 12px;
font-size: 14px;
text-align: left;
}
.dropdown-list{
  max-height: 200px;
}
th {
/* background-color: #f2f2f2; */
font-weight: bold;
}

td {
font-size: 0.9em;
}

.error-message {
text-align: center;
color: red;
font-size: 1.2em;
}
hr {
  border: none; 
  height: 1px; 
  background-color: #ccc; 
  margin: 0 20px; 
}

.table-header {
display: flex;
justify-content: space-between;
align-items: center;
padding-left: 15px;
padding-right: 15px;
}

.add-role-button {
background-color: #3F9BA1;
color: white;
padding: 8px 16px;
border: none;
cursor: pointer;
border-radius: 20px;
}

.add-role-button:hover {
background-color: #2f7b7e;
}
.add-role-button:disabled {
background-color: #cccccc;
cursor: not-allowed;
}
.add-role-modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}

.add-role-form {
background-color: white;
padding: 20px;
border-radius: 10px;
width: 400px;
position: relative;
}

.add-role-header {
display: flex;
justify-content: space-between;
align-items: center;
}

.close-modal {
background: none;
border: none;
font-size: 1.5em;
cursor: pointer;
}

.operations {
display: flex;
flex-direction: column;
/* justify-content: space-between; */
/* margin-top: 20px; */
}

.modal-footer {
display: flex;
flex-direction: row;
justify-content: space-between;
margin-top: 20px;
}
.checkbox-container {
display: flex;
flex-wrap: wrap; 
}

.admin-label {
flex: 0 0 25%; 
box-sizing: border-box; 
padding: 0.5em; 
margin-bottom: 0.5em; 
}


.admin-label input {
margin-right: 0.5em; 
}

.radio-label{
  display: flex;
}
.radio{
  border: none;
  border-radius: 5px;
  outline: 0;
  padding: 10px;
  width: auto;
}
.add-regulator-modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}

.add-regulator-form {
background-color: white;
padding: 20px;
border-radius: 10px;
width: 400px;
position: relative;
}

.add-regulator-header {
display: flex;
justify-content: space-between;
align-items: center;
}
.selected-lobs {
display: flex;
flex-wrap: wrap;
margin-top: 8px; 
}

/* .chip {
background-color: #E6EBF0; 
border-radius: 16px; 
padding: 5px 10px; 
margin-right: 8px; 
margin-bottom: 8px; 
display: flex; 
align-items: center; 
} */

.chips-container {
display: flex;
flex-wrap: wrap;
gap: 4px; 
}
.lob-chip {
background-color: #E0F7FA; 
}
.floating-label-container {
position: relative;
margin-bottom: 20px;
}

.floating-label {
display: flex;
flex-direction: column;
}

.floating-label textarea,
.floating-label select {
padding: 8px;
font-size: 16px;
border: 1px solid #ccc;
border-radius: 4px;
box-sizing: border-box;
}

.floating-label-text {
position: absolute;
top: 50%;
left: 10px;
transition: all 0.2s ease;
font-size: 16px;
color: #aaa;
pointer-events: none;
transform: translateY(-50%);
}

.floating-label-container.has-value .floating-label-text {
top: -15px;
font-size: 12px;
color: #333;
}

.floating-label select {
width: 100%;
}
.search-container {
  margin-right: 20px;
  display: flex;
  justify-content: end;
  gap: 20px;
  width: 50%;
}
.search-input {
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 14px;
  width: 25%;
  padding: 5px 10px;
}

.chip {
background-color: #E6EBF0; 
  border-radius: 16px; 
  padding: 4px 12px; 
  font-size: 0.9em; 
  color: #333;
  display: inline-block; 
  margin: 0; 
}

.read-chip {
  background-color: #D7EDFF; 
}

.write-chip {
  background-color: #D7EEEF; 
}

.remove-chip {
margin-left: 8px; 
cursor: pointer; 
color: #ff4d4d; 
}

.cancel-button, .admin-submit-button {
background-color: #3F9BA1;
color: white;
padding: 10px 20px;
border: none;
cursor: pointer;
border-radius: 20px;
}

.cancel-button:hover, .admin-submit-button:hover {
background-color: #2f7b7e;
}
.add-acl-modal{
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: flex;
justify-content: center;
align-items: center;
}
.acl-dropdown {
width: 100%;
padding: 8px;
max-height: 200px; 
overflow-y: auto;
margin-bottom: 15px;
border-radius: 4px;
border: 1px solid #ccc;
}
.custom-table {
border: none;
width: 100%; 
}

.custom-table th,
.custom-table td {
  border-bottom: 1px solid #F0F3F6;
}

.action-buttons {
display: flex; 
align-items: center; 
}

.edit-button,
.delete-button {
background: transparent; 
border: none; 
padding: 0; 
cursor: pointer; 
margin-right: 5px; 
}
.edit-button:disabled{
cursor: not-allowed;
}
.action-icon {
width: 20px; 
height: 20px; 
}

.custom-header{
background-color: transparent;
border-bottom: 1px solid #7B818A;
}
.custom-header th {
  position: relative;
  cursor: pointer;
}

.sort-icons {
  margin-left: 5px;
  display: none;
  font-size: 0.8em;
}

.custom-header th:hover .sort-icons {
  display: inline;
}

.sort-icons.asc .fa-arrow-up {
  color: black; 
}

.sort-icons.desc .fa-arrow-down {
  color: black; 
}

.sort-icons i {
  color: lightgray; 
  margin-left: 3px;
  transition: color 0.2s;
}

.lob-container {
margin: 10px 0;
}

label {
display: block;
margin-bottom: 5px;
}

select {
width: 100%;
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px; 
cursor: pointer;
}
.user-type-selection {
  margin-bottom: 20px; 
  display: flex;
}

.user-type-selection label {
  margin-right: 20px; 
}

.token-content {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
margin: 20px 0;
}

.token-buttons {
display: flex;
  gap: 10px; 
}

.copy-token-button{
background-color: #3F9BA1;
color: white;
padding: 8px 16px;
border: none;
cursor: pointer;
border-radius: 20px;
}
.regenerate-token-button{
background-color: #3F9BA1;
color: white;
padding: 8px 16px;
border: none;
cursor: pointer;
border-radius: 20px;
}

