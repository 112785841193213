.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 450px;
  max-width: 90%;
  position: relative;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 0;
  color: black;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}
.subject-label {
  display: flex;
  align-items: center;
}
.selected-files{
  display: flex;
  flex-direction: column;
}
.date{
  display: block;
  font-family: Manrope !important;
  margin-bottom: 5px;
  width: 108px;  
}
.upload-file{
  display: block;
  font-family: Manrope !important;
  margin-bottom: 5px;
  width: 17px;
}
.form-group label {
  width: 40%;
  margin-right: 10px; 
  font-weight: bold;
  color: black;
}

.input-field {
  flex: 2; 
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
}

.popup-submit-button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: #3f9ba1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.popup-submit-button:hover {
  background-color: #1198a1;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #333; 
}

.info-icon {
margin-left: 8px;
color: #555;
cursor: pointer;
}

.tooltip {
position: absolute;
background-color: #333;
color: #fff;
padding: 5px 10px;
border-radius: 4px;
font-size: 12px;
top: 30%;
/* left: 10%; */
white-space: nowrap;
z-index: 1000;
}
.date-tooltip {
position: absolute;
background-color: #333;
color: #fff;
padding: 5px 10px;
border-radius: 4px;
font-size: 12px;
top: 60%;
/* left: 10%; */
white-space: nowrap;
z-index: 1000;
}
.file-tooltip {
position: absolute;
background-color: #333;
color: #fff;
padding: 5px 10px;
border-radius: 4px;
font-size: 12px;
top: 75%;
/* left: 10%; */
white-space: nowrap;
z-index: 1000;
}
.regulator-tooltip {
position: absolute;
background-color: #333;
color: #fff;
padding: 5px 10px;
border-radius: 4px;
font-size: 12px;
top: 45%;
/* left: 10%; */
white-space: nowrap;
z-index: 1000;
}
.selected-file-text {
  max-width: 260px;
  overflow: hidden; 
  white-space: nowrap; 
  text-overflow: ellipsis; 
  display: inline-block; 
  cursor: pointer;
}

.tooltip-file {
  display: none; 
  position: absolute; 
  background-color: #333; 
  color: #fff; 
  padding: 5px; 
  border-radius: 4px; 
  z-index: 1000; 
}

.selected-file-text:hover .tooltip-file {
  display: block; 
}

.input-error {
border-color: red;
}

.error-text {
color: red;
font-size: 12px;
margin-top: 5px;
}
.required-asterisk {
color: red; 
margin-left: 4px;
}

.type-tooltip{
  position: absolute;
background-color: #333;
color: #fff;
padding: 5px 10px;
border-radius: 4px;
font-size: 12px;
top: 70%;
/* left: 10%; */
white-space: nowrap;
z-index: 1000;
}