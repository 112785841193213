.chat-compare-document {
    background-color: #fff;
    height: auto;
    overflow: auto;
    padding: 10px;
    width: 100%;
  }
  .chat-clauses-table {
    border-collapse: collapse;
    font-size: 14px;
    margin: 20px;
    text-align: left;
    width: 100%;
  }
  .chat-clauses-table td {
  vertical-align: top;
  padding: 8px;
  text-align: left;  /* Ensure left alignment */
  }
  
  .chat-clauses-table td ul {
  padding-left: 20px;
  margin: 0;
  list-style-position: inside;
  }
  
  .chat-clauses-table td li {
  list-style-type: disc; /* Standard bullet points */
  margin-left: 10px;
  margin-bottom: 4px; /* Adds spacing between points */
  text-align: left; /* Ensures text is aligned to the left */
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-size: 12px;
  }
  
  .chat-clauses-table thead {
    /* background-color: #f5f5f5; */
    border-bottom: 2px solid #e5e5e5;
  }
  
  .chat-clauses-table th {
    padding: 12px 30px;
    font-weight: bold;
    white-space: nowrap;
    font-family: 'Manrope', sans-serif;
    color: black;
    font-size: 12px;
  }
  
  .chat-clauses-table td {
    padding: 12px 30px;
    color: black;
    /* width: 10px; */
    max-width: 250px; 
    word-wrap: break-word; 
    overflow-wrap: break-word; 
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    vertical-align: top;
  }
  
  .chat-clauses-table tbody tr {
    border-bottom: 1px solid #ddd;
  }
  
  .chat-clauses-table tbody tr:nth-child(even) {
    /* background-color: #f9f9f9; */
  }
  
  .chat-clauses-table tbody tr:hover {
    background-color: #f2f2f2;
  }
  
  .chat-clauses-table td:first-child,
  .chat-clauses-table th:first-child {
    /* width: 25%; */
  }
  
  .chat-clauses-table td:last-child,
  .chat-clauses-table th:last-child {
    text-align: center;
  }
  
  .chat-clauses-table td.status-exact {
    color: green;
    font-weight: bold;
  }
  
  .chat-clauses-table td.status-partial {
    color: orange;
    font-weight: bold;
  }
  
  .chat-clauses-table td.status-missing {
    color: red;
    font-weight: bold;
  }
  
  .chat-clauses-table td.status-additional {
    color: blue;
    font-weight: bold;
  }
  
  .summary-statistics {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* .summary-statistics h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  } */
  
  .statistics-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .stat {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 8px;
  }
  
  .exact-stat {
    background-color: #d4edda;
    color: #155724;
  }
  
  .new-stat {
    background-color: #cce5ff;
    color: #004085;
  }
  
  .partial-stat {
    background-color: #fff3cd;
    color: #856404;
  }
  
  .missing-stat {
    background-color: #f8d7da;
    color: #721c24;
  }
  
  
  .summary-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .summary-header h2 {
    margin: 0 auto;
  }
  
  .export-button {
    margin-left: 0;
    margin-right: 11px;
    padding: 10px;
    color: blue;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
  }
  .download-error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  
  .file-upload-container {
  margin-bottom: 20px;
}

.file-box {
  border: 2px dashed #d6d6d6;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.file-box:hover {
  background-color: #e6f7f8;
}

.file-input {
  display: none;
}

.file-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: #555;
}
.compare-loader-container{
  top: 0;
  left: 10%;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  .compare-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .compare-loader-circle {
    animation: spin 2s linear infinite;
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top-color: #438ed8;
    height: 50px;
    width: 50px;
    margin-bottom: 8px; /* Spacing between loader and text */
  }

.file-label span {
  color: black;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  font-family:'Manrope', sans-serif; 
}

.status-summary {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 10px;
}

.status-summary span {
  font-size: 14px;
  padding: 4px 8px;
  text-align: center;
  width: 50%;
  border-radius: 30px;
}
.dashed-line {
  flex: 0 1 auto;
  border-bottom: 1px dashed #999;
  margin: 0 10px;
  height: 0;
  align-self: center;
  width: 50%;
}
.exact-match {
  background: #D7EEEF;
  color: black;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}

.partial-match {
  background: rgba(16, 107, 197, 0.18);
  color: black;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}

.missing {
  background: rgba(205, 63, 63, 0.14); 
  color: black;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}

.new {
  background: rgba(228, 155, 29, 0.25);
  color: black;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
}
.clauses-table {
  width: 95%;
  border-collapse: collapse;
  margin: 20px 20px;
  font-size: 14px;
  text-align: left;
  /* border: 1px solid #ddd;
  background-color: #ffffff; */
}

.clauses-table thead {
  /* background-color: #f5f5f5; */
  border-bottom: 2px solid #e5e5e5;
}

.clauses-table th {
  padding: 12px 30px;
  font-weight: bold;
  font-family: 'Manrope', sans-serif;
  color: black;
  font-size: 12px;
}

.clauses-table td {
  padding: 12px 30px;
  color: black;
  width: 10px;
  font-family: 'Manrope', sans-serif;
  font-size: 12px;
  vertical-align: top;
}

.clauses-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.clauses-table tbody tr:nth-child(even) {
  /* background-color: #f9f9f9; */
}

.clauses-table tbody tr:hover {
  background-color: #f2f2f2;
}

.clauses-table td:first-child,
.clauses-table th:first-child {
  /* width: 25%; */
}

.clauses-table td:last-child,
.clauses-table th:last-child {
  text-align: center;
}

.clauses-table td.status-exact {
  color: green;
  font-weight: bold;
}

.clauses-table td.status-partial {
  color: orange;
  font-weight: bold;
}

.clauses-table td.status-missing {
  color: red;
  font-weight: bold;
}

.clauses-table td.status-additional {
  color: blue;
  font-weight: bold;
}

.upload-btn {
  /* background-color: white; */
  border: 1px solid black;
  color: #3F9BA1;
  font-weight: 500;
  font-family: 'Manrope', sans-serif;
  line-height: 20px;
  padding: 8px 16px;
  border-radius: 25px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.upload-btn:hover {
  background-color: #347f84;
}

  
.uploaded-files-container {
  margin-top: 20px;
  padding: 10px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* background-color: #f9f9f9; */
  max-width: 400px;
  word-wrap: break-word;
}

.uploaded-files-container h3 {
  margin-bottom: 17px;
  font-family: 'Manrope', sans-serif; 
  font-size: 14px;
  color: black;
}

.uploaded-files-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.uploaded-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 8px;
  padding: 6px; */
  font-family: 'Manrope', sans-serif; 
  background-color: #ffffff;
  font-weight: bold;
  /* border: 1px solid #ddd; */
  border-radius: 4px;
  overflow: hidden;
}

.uploaded-file-item:last-child {
  margin-bottom: 0;
}

.file-name {
  flex-grow: 1;
  font-size: 15px;
  color: black;
  overflow: hidden;
  line-height: 24px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif; 
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.remove-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-icon {
  width: 16px;
  height: 16px;
}

  
  .compare-btn {
    width: 100%;
    padding: 12px;
    background-color: #3f9ba1;
    color: #ffffff;
    font-weight: 500;
    line-height: 20px;
    font-family: 'Manrope', sans-serif; 
    border: none;
    border-radius: 40px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  ul {
    padding-left: 0;
  }
  
  li {
    /* margin: 10px 0; */
    list-style: none;
  }
  
  .section-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 8px 15px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .section-item:hover {
    background-color: #E6EBF0;
  }
  .section-part{
    display: flex;
    flex-direction: row;
    width: 45%;
    justify-content: space-between;
  }
  .expand-arrow {
    margin-right: 10px;
    font-size: 18px;
    color: #333;
  }
  
  .section-title {
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    width: 70%;
    color: #20324E;
  }
  
  .sub-section-list {
    margin-left: 20px;
    padding-left: 20px;
    list-style: none;
  }
  .sub-section-title{
    font-weight: 600;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    color: #20324E;
  }
  .sub-section-item {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }
  
  .sub-section-item:hover {
    /* color: #007bff; */
    cursor: pointer;
  }
  
  .sub-section-item:active {
    color: #0056b3;
  }
  
  /* .compare-btn:hover {
    background-color: #177ccb;
  } */
  
.rbac-container {
    display: flex;
  flex-direction: column;
  height: 100vh;
  background-color:#fff ;
  width: 100vw;
  pointer-events: auto;
  }
  .complieaze-header{
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 8vh;
    width: 100vw;
    border-width: 0px 0px 1px 0px;
    border-style: solid;
    border-color: var(--gray, #E6EBF0);
  }
  .admin-complieaze-logo {
  background: #fff;
  border: 1px solid #e6ebf0;
  border-color: var(--gray, #e6ebf0);
  display: flex;
  flex-direction: column;
  padding-left: 28px;
  width: 15.9vw;
  }
  .sidebar {
    width: 15.9vw;
  padding: 16px;
  position: relative;
  /* border-radius: 6px; */
  background: #fff;
  display: flex;
  flex-direction: column;
  /* margin-top: 70px;
  margin-bottom: 50px;
  margin-left: 70px; */
  overflow-y: hidden;
  border-width: 0px 1px 0px 0px; 
  border-style: solid;
  border-color: var(--gray, #E6EBF0);
  }
  
  .sidebar-header {
    margin-bottom: 40px;
  }
  
  .sidebar-header h2 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }
  
  .sidebar-header p {
    font-size: 12px;
    color: #888;
  }
  
  .sidebar-menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .sidebar-button {
    padding: 10px 15px;
    background-color:transparent;
    border: none;
    color: #333;
    text-align: left;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  .token{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 300px;  */
    max-width: 50%;
    height: 50px;
    border: 1px solid #ccc; 
    border-radius: 5px;
    background-color: #f9f9f9; 
    font-size: 1.2em; 
    font-family: 'Manrope', sans-serif; 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px;
    margin-top: 10px;
  }
  .sidebar-button:hover {
    background-color: #d6dde5;
  }
  .sidebar-button.active {
    background-color: #E6EBF0; 
    font-weight: bold;
    /* box-shadow: inset 0px 0px 5px #00000055;  */
  }
  
  .main-container {
    flex-grow: 1;
    display: flex;
    width: 80vw;
    flex-direction: column;
    background-color: #e6ebf0;
    padding: 20px;
  }
  .compare-document{
    background-color: #fff;
    height: 70vh;
    padding: 10px;
    overflow: auto;
  }
  .compare-documents-container{
    background-color: #fff;
    height: 86vh;
    /* overflow: auto; */
  }
  .upload-documents-message{
    background-color: #f0f3f6;
    height: 15vh;
    width: 45vw;
    justify-self: center;
    align-self: center;
    margin: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  .upload-documents-message h3{
    font-size: 16px;
    font-family: 'Manrope', sans-serif;
    color: #000000;
  }
  .admin-body-container{
    display: flex;
    height: 92vh;
    width: 100%;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  .admin-loader-container{
    position: absolute;
    z-index: 2;
    top: 50vh;
    left: 50vw;
  }
  .admin-role{
    border: 1px solid #ccc !important;
    border-radius: 5px o !important;
    font-size: 14px !important;
    height: 25px !important;
    margin-bottom: 13px !important;
    padding: 8px !important;
    resize: vertical;
    font-family: 'Manrope', sans-serif;
    width: calc(100% - 8px) !important;
  }
  .admin-description{
    border: 1px solid #ccc !important;
    border-radius: 5px o !important;
    font-size: 14px !important;
    margin-bottom: 13px !important;
    padding: 8px !important;
    height: 25px !important;
    resize: vertical;
    font-family: 'Manrope', sans-serif;;
    width: calc(100% - 8px) !important;
  }
  .checkbox-container {
    display: flex;
    flex-direction: row;
    gap: 10px; 
    align-items: center; 
  }
  
  .header-title {
    font-size: 24px;
    font-weight: 600;
  }
  
  .header-actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .generate-token-btn {
    padding: 8px 12px;
    background-color: #008c96;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .generate-token-btn:hover {
    background-color: #006d72;
  }
  
  .profile-icon {
    font-size: 24px;
    cursor: pointer;
  }
  
  .content {
    padding-top: 20px;
  }
  
  h3 {
    font-weight: 500;
  }
  
  table {
  width: 100%;
  border-collapse: collapse;
  }
  
  th, td {
  padding: 12px;
  font-size: 14px;
  text-align: left;
  }
  .dropdown-list{
    max-height: 200px;
  }
  th {
  /* background-color: #f2f2f2; */
  font-weight: bold;
  }
  
  td {
  font-size: 0.9em;
  }
  
  .error-message {
  text-align: center;
  color: red;
  font-size: 1.2em;
  }
  hr {
    border: none; 
    height: 1px; 
    background-color: #ccc; 
    margin: 0 20px; 
  }
  
  .table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  }
  .table-header h3{
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    font-family:'Manrope', sans-serif; 
  }
  .header-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    }
    .header-table h3{
      font-size: 17px;
      font-weight: 600;
      line-height: 24px;
      font-family:'Manrope', sans-serif; 
    }
  .add-role-button {
  background-color: #3F9BA1;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  }
  
  .add-role-button:hover {
  background-color: #2f7b7e;
  }
  .add-role-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  }
  .add-role-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  .add-role-form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  position: relative;
  }
  
  .add-role-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  
  .close-modal {
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  }
  
  .operations {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* margin-top: 20px; */
  }
  
  .modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  }
  .checkbox-container {
  display: flex;
  flex-wrap: wrap; 
  }
  
  .admin-label {
  flex: 0 0 25%; 
  box-sizing: border-box; 
  padding: 0.5em; 
  margin-bottom: 0.5em; 
  }
  
  
  .admin-label input {
  margin-right: 0.5em; 
  }
  
  .radio-label{
    display: flex;
  }
  .radio{
    border: none;
    border-radius: 5px;
    outline: 0;
    padding: 10px;
    width: auto;
  }
  .add-regulator-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  .add-regulator-form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  position: relative;
  }
  
  .add-regulator-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  .selected-lobs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px; 
  }
  
  /* .chip {
  background-color: #E6EBF0; 
  border-radius: 16px; 
  padding: 5px 10px; 
  margin-right: 8px; 
  margin-bottom: 8px; 
  display: flex; 
  align-items: center; 
  } */
  
  .chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px; 
  }
  .lob-chip {
  background-color: #E0F7FA; 
  }
  .floating-label-container {
  position: relative;
  margin-bottom: 20px;
  }
  
  .floating-label {
  display: flex;
  flex-direction: column;
  }
  
  .floating-label textarea,
  .floating-label select {
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  }
  
  .floating-label-text {
  position: absolute;
  top: 50%;
  left: 10px;
  transition: all 0.2s ease;
  font-size: 16px;
  color: #aaa;
  pointer-events: none;
  transform: translateY(-50%);
  }
  
  .floating-label-container.has-value .floating-label-text {
  top: -15px;
  font-size: 12px;
  color: #333;
  }
  
  .floating-label select {
  width: 100%;
  }
  .search-container {
    margin-right: 20px;
    display: flex;
    justify-content: end;
    gap: 20px;
    width: 50%;
  }
  .search-input {
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 14px;
    width: 25%;
    padding: 5px 10px;
  }
  
  .chip {
  background-color: #E6EBF0; 
    border-radius: 16px; 
    padding: 4px 12px; 
    font-size: 0.9em; 
    color: #333;
    display: inline-block; 
    margin: 0; 
  }
  
  .read-chip {
    background-color: #D7EDFF; 
  }
  
  .write-chip {
    background-color: #D7EEEF; 
  }
  
  .remove-chip {
  margin-left: 8px; 
  cursor: pointer; 
  color: #ff4d4d; 
  }
  
  .cancel-button, .admin-submit-button {
  background-color: #3F9BA1;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  }
  
  .cancel-button:hover, .admin-submit-button:hover {
  background-color: #2f7b7e;
  }
  .add-acl-modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .acl-dropdown {
  width: 100%;
  padding: 8px;
  max-height: 200px; 
  overflow-y: auto;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
  }
  .custom-table {
  border: none;
  width: 100%; 
  }
  
  .custom-table th,
  .custom-table td {
    border-bottom: 1px solid #F0F3F6;
  }
  
  .action-buttons {
  display: flex; 
  align-items: center; 
  }
  
  .edit-button,
  .delete-button {
  background: transparent; 
  border: none; 
  padding: 0; 
  cursor: pointer; 
  margin-right: 5px; 
  }
  .edit-button:disabled{
  cursor: not-allowed;
  }
  .action-icon {
  width: 20px; 
  height: 20px; 
  }
  
  .custom-header{
  background-color: transparent;
  border-bottom: 1px solid #7B818A;
  }
  .custom-header th {
    position: relative;
    cursor: pointer;
  }
  
  .sort-icons {
    margin-left: 5px;
    display: none;
    font-size: 0.8em;
  }
  
  .custom-header th:hover .sort-icons {
    display: inline;
  }
  
  .sort-icons.asc .fa-arrow-up {
    color: black; 
  }
  
  .sort-icons.desc .fa-arrow-down {
    color: black; 
  }
  
  .sort-icons i {
    color: lightgray; 
    margin-left: 3px;
    transition: color 0.2s;
  }
  
  .lob-container {
  margin: 10px 0;
  }
  
  label {
  display: block;
  margin-bottom: 5px;
  }
  
  select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px; 
  cursor: pointer;
  }
  .user-type-selection {
    margin-bottom: 20px; 
    display: flex;
  }
  
  .user-type-selection label {
    margin-right: 20px; 
  }
  
  .token-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px 0;
  }
  
  .token-buttons {
  display: flex;
    gap: 10px; 
  }
  
  .copy-token-button{
  background-color: #3F9BA1;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  }
  .regenerate-token-button{
  background-color: #3F9BA1;
  color: white;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 20px;
  }
  
  .file-upload-container {
    margin-bottom: 20px;
  }
  
  .file-box {
    border: 2px dashed #d6d6d6;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 20px;
    text-align: center;
    position: relative;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  
  .file-box:hover {
    background-color: #e6f7f8;
  }
  
  .file-input {
    display: none;
  }
  
  .file-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: #555;
  }
  