.multi-select-dropdown {
    /* width: 300px; */
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative; 
  }
  
  .dropdown-header {
    padding: 10px;
    width: 95%;
    cursor: pointer;
    display: flex; 
    align-items: center; 
  }
  
  .search-bar {
    flex: 1; 
    padding: 5px;
    border: none; 
  }
  .dropdown-placeholder{
    width: 100%;
  }
  .arrow {
    margin-left: 10px; 
  }
  
  .dropdown-options {
    max-height: 200px;
    overflow-y: auto;
    border-top: 1px solid #ccc;
    padding: 5px 0;
    background-color: white; 
    position: absolute; 
    width: 100%; 
    z-index: 1; 
  }
  
  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }
  
  .dropdown-item input {
    margin-right: 10px;
  }
  .selected-operations {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px; 
  }
  
  .dropdown-chip {
    background-color: #E6EBF0; 
  border-radius: 16px; 
  padding: 4px 12px; 
  font-size: 0.9em; 
  color: #333;
  display: inline-block; 
  margin: 0; 
  }
  .remove-chip {
    margin-left: 8px; 
    cursor: pointer; 
    color: #ff4d4d; 
    }
  .chip button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 5px;
  }
  .dropdown-chips-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 4px; 
    }
    