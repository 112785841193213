.audit-header {
    flex: 1;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}
  
.audit-header-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.audit-complieaze-logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    width: 100%; /* Take the full width of the parent container */
    padding-left: 1rem; /* Add some left padding for spacing */
    margin-top: -91px;
  }
  
  .audit-logo {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Manrope', sans-serif;
    letter-spacing: 0px;
  }
  
  .audit-logo span:first-child {
    color: #3F9BA1; /* Color for 'Compli' */
  }
  
  .audit-logo span:last-child {
    color: #000; /* Color for 'eaze' */
  }
  
  .audit-titlee {
    margin-top: 0.25rem;
    font-size: 16px;
    text-align: left; /* Align text to the left */
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    color: #20324E;
    opacity: 0.8;
  }

  .download-pdf-button {
    padding: 8px 16px;
    background-color: #007bff; /* Blue color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 10px; /* Space between download button and profile button */
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
}

.download-pdf-button:hover:not(:disabled) {
    background-color: #0056b3; /* Darker blue for hover */
}

.download-pdf-button:disabled {
    background-color: #afc0e4; 
    cursor: not-allowed;
}
  
.spinner {
  border: 3px solid rgba(255, 255, 255, 0.3); /* Semi-transparent white for background */
  border-top: 3px solid #ffffff; /* Bright white for the spinning part */
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin-right: 8px; 
}

/* Keyframes for spinner rotation */
@keyframes spin {
  to {
      transform: rotate(360deg);
  }
}
  