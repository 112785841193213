.pdf-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center; 
    align-items: center; 
    z-index: 1000; 
  }
  
  .pdf-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); 
    max-width: 80%; 
    max-height: 80%; 
    overflow: auto; 
  }
  
  .pdf-modal-close {
    color: #f10909;
    font-size: 28px;
    font-weight: bold;
  }
  
  .pdf-modal-content iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
  