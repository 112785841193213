.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
    font-family: 'Arial', sans-serif;
}

.error-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 500px;
    text-align: center;
}
.access-logo{
    font-family: Manrope;
    letter-spacing: 0;
    max-width: 100%;
    opacity: 1;
    color: #4fc2c9;
    font-size: 20px;
    font-weight: 600;
    height: 27px;
    padding: 8px 8px 0 5px;
}
.error-logo {
    width: 100px;
    margin-bottom: 20px;
}

.error-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
}

.error-message {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.contact-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: #0056b3;
}
