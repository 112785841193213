/* .home-sectionMob {
  background-position: top;
  background-repeat: no-repeat;
  width: 100%;
  height: 770px;
  background: transparent url("../../assets/homeBackground.jpg") 47% 86%
    no-repeat padding-box;
  opacity: 1;
  margin-top: 0%;
} */
body {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
  }
  
  .home-sectionMob {
    background-repeat: no-repeat;
    height: 880px;
    width: 100%;
    background: transparent url("../../assets/homeBackground.jpg") 47% 190%
      no-repeat padding-box;
    opacity: 1;
    margin-top: 0%;
    padding: 20px;
    background-size: cover;
  }
  
  .homeTextMob {
    font-family: Zoho Puvi, Bold;
    color: var(--white);
    letter-spacing: 0px;
    color: #ffffff;
    text-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    position: relative;
    font-size: 35px;
    padding-top: 60px;
  }
  
  .demo-buttonApp1 {
    width: 35%;
    height: 45px;
    background-color: #4fc2c9;
    color: #ffffff;
    border-radius: 6px;
    font-size: 18px;
    font-size: "Zoho Puri";
    position: relative;
    border: none;
    margin-bottom: 15px;
  }
  
  .bg-small-container {
    background-color: rgb(255 255 255 / 14%);
    padding: 10px;
    border-radius: 20px;
    margin-right: 40px;
    margin-top: 15px;
  }
  
  .home-section-heading {
    color: #4fc2c9;
    font-size: 20px;
    font-size: "Zoho Puri";
    font-weight: 500;
  }
  
  .home-section-paragraph {
    font-size: 20px;
    font-size: "Zoho Puri";
    color: #ffffffcf;
  }
  
  .bg-container-feature-section {
    background-color: #ffffff;
    height: 100vh;
    background-size: cover;
    padding: 25px;
  }
  
  .feature-heading {
    color: black;
    font-size: 30px;
    font-size: "Zoho Puri";
    font-weight: bold;
    text-align: center;
  }
  
  .complex {
    color: #4fc2c9;
    font-weight: bold;
  }
  
  .bg-work-overload {
    background-color: #f7faff;
    background-size: cover;
    margin-top: 90px;
  }
  
  .overloadImage {
    width: 100%;
    height: 215px;
  }
  
  .overload-heading {
    color: black;
    font-size: 35px;
    font-size: "Zoho Puri";
    font-weight: bold;
    margin-left: 20px;
  }
  
  .overload-span {
    color: #4fc2c9;
  }
  
  .overload-paragraph {
    color: black;
    font-size: 16px;
    font-size: "Zoho Puri";
    font-weight: 500;
    margin-left: 20px;
    padding-bottom: 23px;
  }
  
  .largeUnstructured {
    width: 100%;
    height: 215px;
  }
  
  .overload-bankereaze-heading {
    color: #4fc2c9;
    font-size: 31px;
    font-size: "Zoho Puri";
    font-weight: bold;
    margin-left: 18px;
    width: 100%;
  }
  
  .overload-bankereaze-paragraph {
    color: black;
    font-size: 22px;
    font-size: "Zoho Puri";
    font-weight: 500;
    margin-left: -13px;
    text-align: center;
    width: 100%;
  }
  
  .bg-whiteColor {
    background-color: #ffffff;
    background-size: cover;
    padding: 0px;
    box-shadow: 0px 10px 40px #31394629;
    border-radius: 6px;
    padding: 20px;
    z-index: 1;
    position: relative;
  }
  
  .ass-heading {
    color: black;
    font-size: 25px;
    font-size: "Zoho Puri";
    font-weight: 700;
  }
  
  .ass-heading {
    color: black;
    font-size: 18px;
    font-size: "Zoho Puri";
    font-weight: 500;
  }
  .backgroundCurveMob {
    background-color: rgb(16, 40, 65);
    border-radius: 0px 0px 120px 0px;
    color: white;
    font-size: 32px;
    height: 941px;
    position: relative;
    z-index: 0;
    position: relative;
    font-family: Zoho Puvi;
    margin-top: -184%;
    padding: 0px;
    width: 422px;
    left: -20px;
  }
  .nextTextMob {
    position: relative;
    top: 75%;
    left: 6%;
    font-size: 32px;
    font-weight: bolder;
    inline-size: 389px;
  }
  
  .demo-buttonFeatureMob {
    top: 78%;
    left: 34%;
    background: #4fc2c9 0% 0% no-repeat padding-box;
    border-radius: 6px;
    border: none;
    text-align: center;
    font: normal normal normal 18px/20px Zoho Puvi;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    cursor: pointer;
    position: relative;
    height: 36px;
    width: 28%;
  }
  
  .domainExperts-heading {
    color: black;
    font-size: 30px;
    font-family: "Zoho Puri";
    font-weight: bold;
    text-align: center;
    margin-top: 50px;
  }
  
  .domainExpertsImage {
    width: 100%;
    height: 215px;
    margin-bottom: 35px;
  }
  
  .card-domainExpert {
    background-color: #ffffff;
    background-size: cover;
    box-shadow: 0px 3px 20px #00000029;
    padding-left: 30px;
    padding-top: 3px;
    margin: 11px;
  }
  
  .card-domainExpert-heading {
    color: #4fc2c9;
    font-size: 24px;
    font-weight: 500;
    margin-top: 11px;
  }
  
  .card-domainExpert-paragraph {
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin-top: -11px;
  }
  
  .card-end-to-end {
    background-color: white;
    border: 1px solid #eaeff7;
    margin: 10px;
    margin-top: -4px;
  }
  
  .card-end-to-end-heading {
    color: black;
    font-size: 20px;
    font-weight: 500;
    margin-top: 11px;
    margin-left: 30px;
  }
  .bg-priceSection {
    background-color: #4fc2c9;
    background-size: cover;
    height: 1680px;
    border-top-left-radius: 101px;
    padding-top: 10px;
  }
  
  .priceSection-heading {
    color: #102841;
    font-size: 23px;
    font-weight: 700;
    text-align: left;
    width: 100%;
    text-align: center;
    padding: 10px;
  }
  
  .priceSectionCard1 {
    background-color: #ffffff;
    background-size: cover;
    margin: 40px;
    width: 350px;
    height: 480px;
    border-radius: 20px;
    box-shadow: 0px 3px 20px #00000029;
  }
  
  .priceSectionCard2 {
    background-color: #ffffff;
    background-size: cover;
    margin: 40px;
    width: 350px;
    height: 470px;
    border-radius: 20px;
    box-shadow: 0px 3px 20px #00000029;
  }
  
  .priceSectionCard3 {
    background-color: #ffffff;
    background-size: cover;
    margin: 40px;
    width: 350px;
    height: 470px;
    border-radius: 20px;
    box-shadow: 0px 3px 20px #00000029;
  }
  
  .priceSectionCard-heading {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    padding-top: 30px;
    margin-bottom: 15px;
  }
  
  .breakLine1 {
    width: 300px;
    height: 3px;
    background-color: #4fc2c9;
    display: inline-block;
  }
  
  .breakLine2 {
    width: 250px;
    height: 1px;
    background-color: lightgrey;
    display: inline-block;
  }
  
  .line {
    text-align: center;
  }
  
  .Assistant {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    padding-top: 7px;
    padding-bottom: 85px;
  }
  
  .assi-premium {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    padding-top: 7px;
  }
  
  .tokens {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #102841;
    font-family: "Roboto";
  }
  
  .enterprise-heading {
    font-size: 21px;
    font-weight: 500;
    text-align: center;
    padding-top: 7px;
  }
  
  .enterprise {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #102841;
    font-family: "Roboto";
  }
  
  .get-banker-heading {
    font-size: 35px;
    font-weight: 600;
    text-align: center;
    padding-top: 40px;
  }
  
  .get-banker-paragraph {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: #102841;
    font-family: "Roboto";
    padding-bottom: 50px;
  }
  
  .container {
    background-color: #f7faff;
    margin: 15px;
    padding: 25px;
    height: 64vh;
  }
  
  .form-control {
    margin: 5px;
    width: 97%;
    height: 35px;
    border: 1px solid #f0f3f8;
    border-radius: 4px;
  }
  
  .form-control1 {
    margin: 5px;
    width: 295px;
    height: 70px;
    border: 1px solid #f0f3f8;
    border-radius: 4px;
  }
  
  .form-control-looking {
    margin: 5px;
    height: 35px;
    border: 1px solid #f0f3f8;
    border-radius: 4px;
    background: white;
    padding-left: 20px;
    opacity: 0.5;
    width: 319px;
  }
  .form-control {
    margin: 5px;
    width: 300px;
    height: 19px;
    border: 1px solid #f0f3f8;
    border-radius: 4px;
  }
  form {
    padding: 0px;
  }
  .clicking {
    color: #4fc2c9;
  }
  
  .button {
    width: 98%;
    height: 45px;
    background-color: #4fc2c9;
    color: #ffffff;
    border-radius: 6px;
    font-size: 20px;
    font-family: "Zoho Puri";
    position: relative;
    border: none;
    margin-bottom: px;
    margin: 8px;
    margin-top: 25px;
  }
  .pricing-sectionMob {
    position: absolute;
    margin-top: 12%;
    width: 100%;
    overflow: hidden;
  }
  .contact-sectionMob {
    position: absolute;
    top: 676%;
    overflow: hidden;
    width: 100%;
    font-family: Zoho Puvi !important;
  }
  
  .feature-cardMob {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 17px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: box-shadow 0.3s;
    background: #ffffff;
    margin: 3px;
    height: 24px;
    width: 342px;
    text-align: left;
    font-size: 17px;
    font-family: Zoho Puvi;
    font-weight: bold;
    border: 1px solid #eaeff7;
    cursor: pointer;
    z-index: 1;
  }
  
  .feature-cardMob:hover {
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .feature-cardMob.active {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding: 20px;
    height: 160px;
    font-weight: 400;
    font-size: 17px;
    width: 88%;
  }
  .feature-cardMob.active h2 {
    color: #4fc2c9;
  }
  .card-containerMob {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    margin-top: 4%;
  }
  .contentWrapperMob {
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .card-imageMob img {
    width: 100%;
  }
  .mobBox {
    width: 5%;
  }
  .textPolicyMob {
    font-size: 13px;
    inline-size: 508px;
  }
  .featureMob {
    height: 369vh;
    overflow: hidden;
  }
  