/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500&display=swap');
.compliance-dropdown {
  position: relative;
  display: contents;
  z-index: 1;
} */
.complieaze-header{
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 8vh;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: var(--gray, #E6EBF0);
}
.attachment-icon {
position: absolute;
left: 3.7%;
margin-left: 8.4%;
margin-top: 5px;
padding-right: 10px;
color: #000;
transform: translateY(-50%);
cursor: pointer;
}
a:-webkit-any-link {
color: -webkit-link;
cursor: pointer;
text-decoration: none !important;
}
.complieaze-logo{
display: flex;
/* width: 15.9vw; */
padding-left: 28px;
background: white;
  flex-direction: column;
  border-width: 1px 1px 1px 1px; 
  border-style: solid;
  border-color: var(--gray, #E6EBF0);
}
.body-container {
display: flex;
height: 100vh;
width: 100%;
}
.sidebar-container{
display: flex;
flex-direction: column;
height: 100%;
}
/* .floating-download-btn {
position: fixed;
right: 10px; 
z-index: 1000; 
} */

.dark-mode .sidebar-container {
/* background: #fff; */
/* display: none; */
}

.dataeaze-logo{
width: 13.4vw;
align-self: center;
padding-left: 10px;
padding-bottom: 6px;
}
.footer-image-dataeaze {
height: 25px;
margin-bottom: -8px;
margin-left: 3px;
width: 80px;
}
.api-response-table {
width: 100%;
max-width: 1000px;
margin-top: 20px;
border-collapse: collapse;
}
.box-container{
display: flex;
}
.mode-toggle-buttons {
display: flex;
justify-content: center;
margin-bottom: 20px;
}

.mode-button {
padding: 10px 7px;
margin: 0 10px;
border: 1px solid #4fc2c9;
background-color: #fff;
color:#4fc2c9;
cursor: pointer;
transition: all 0.3s;
}

.mode-button.active {
background-color:#4fc2c9;
color: #fff;
cursor: not-allowed;
}

.mode-button:hover:not(.active) {
background-color: #4fc2c9;
color: #fff;
}
.table-container1 {
max-height: 500px; 
margin-bottom: 5%;
}

.api-response-table {
width: 100%;
margin-top: 20px;
border-collapse: collapse;
}

.api-response-table th,
.api-response-table td {
border: 1px solid #ccc;
padding: 8px;
text-align: left;
}

.api-response-table th {
background-color: #f4f4f4;
}

.pagination {
display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;
}

.pagination-button {
padding: 8px 16px;
margin: 0 5px;
background-color: #4fc2c9;
color: #fff;
border: none;
border-radius: 4px;
cursor: pointer;
font-size: 16px;
transition: background-color 0.3s;
}

.pagination-button:disabled {
background-color: #ccc;
cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
background-color: #0056b3;
}

.browse-chat-label {
margin-top: 10px;
width: 201px;
height: 26px;
text-align: left;
font: normal normal 600 20px/26px;
letter-spacing: 0px;
color: #0c111b;
opacity: 1 !important;
}


.browse-document-text{
text-align: left;
color: #0C111B;
font: normal normal 600 20px/26px;
letter-spacing: 0px;
opacity: 1;
/* font-weight: bold;  */
margin-left: 20px;
margin-top: 30px;
font-size: 22px;
user-select: none;
}

.browse-mode-content {
display: flex;
height: 92vh;
width: 80vw;
margin: 10px;
padding-top: 2%;
overflow-y: hidden;
background-color: #FFFFFF;
flex-direction: column;
align-items: center;
}
.browse-mode-content:hover{
overflow-y: auto;
}
.dropdown-container {
display: flex;
align-items: center;
gap: 16px;
flex-direction: column;
padding: 15px;
width: 75vw;
}
.browse-dropdown {
height: 60px;
width: 100%;
padding: 10px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
appearance: none; 
-webkit-appearance: none; 
-moz-appearance: none; 
}
.dropdown-control {
position: relative;
z-index: 1; 
}

.dropdown-open {
z-index: 999; 
}
.dropdown-container1{
display: flex;
justify-content: center;
gap: 30px;
  width: 75vw;
  position: relative;
  align-items: center;
}
.filter-label {
font-size: 18px;
font-weight: 600;
margin-bottom: 8px;
display: block;
font-family: 'Manrope', sans-serif;
color: #333;
}
.browsee-dropdown {
width: 16vw;
height: 48px;
appearance: none; 
-webkit-appearance: none; 
-moz-appearance: none; 
padding: 10px;
margin-bottom: 16px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
}
.inputt-box {
margin-bottom: 16px;
padding: 8px;
width: 33vw;
height: 30px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
display: flex;
align-self: center;
}

.inputt-box:focus {
border-color: black;
outline: none;
}


.submitt-button {
background-color: #4fc2c9;
color: #fff;
border: 1px solid gray;
  cursor: pointer;
  height: 41px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Manrope';
  /* margin-left: 30px; */
  margin-bottom: 7px;
  justify-content: space-around;
  border-radius: 16pc;
  opacity: 1;
  width: 15%;
}
.clear-search-button {
border: 1px solid gray;
  cursor: pointer;
  height: 41px;
  font-size: 14px;
  font-weight: 500;
  color: #3F9BA1;
  font-family: 'Manrope';
  /* margin-left: 30px; */
  margin-bottom: 7px;
  justify-content: space-around;
  border-radius: 16pc;
  opacity: 1;
  width: 13vw;
}
.upload-button{
border: 1px solid gray;
  cursor: pointer;
  height: 41px;
  font-size: 14px;
  font-weight: 500;
  color: #3F9BA1;
  font-family: 'Manrope';
  /* margin-left: 30px; */
  margin-bottom: 7px;
  justify-content: space-around;
  border-radius: 16pc;
  opacity: 1;
  width: 15%;
}

.toggle-switch-container {
display: flex;
align-items: center;
margin-top: 10px;
font-size: 1rem;
}

.toggle-switch-container span {
padding: 0 10px;
font-size: 1rem;
cursor: pointer;
color: black;
}

.toggle-switch-container span.selected {
font-weight: bold;
color: #007bff; 
}


.toggle-switch {
width: 60px;
height: 30px;
border-radius: 30px;
background-color: #ccc;
position: relative;
cursor: pointer;
margin: 0 10px;
}

.toggle-button {
width: 25px;
height: 25px;
border-radius: 50%;
background-color: white;
position: absolute;
top: 2.5px;
transition: left 0.3s ease;
}

.toggle-button.prompt {
left: 2.5px; 
}

.toggle-button.browse {
left: 32.5px; 
}
/* .form-containerrr {
position: absolute;
top: 70px; 
right: 58px;
padding: 20px;
z-index: 3;
background: #fff;
border: 1px solid #ccc;
border-radius: 4px;
box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
max-width: 400px;
width: 200px;
} */

.form-group {
margin-bottom: 15px;
}
.regulator-form{
  margin-bottom: 15px;
  display: block;
  font-weight: bold;
}

.form-group span.key {
display: block;
margin-bottom: 5px;
font-weight: bold;
}
.custom-regulator-label {
font-family: 'Manrope', sans-serif;
font-size: 16px !important; 
color: #333 !important; 
width: 100% !important;
font-weight: bold !important; 
}

.custom-regulator-input {
font-family: 'Manrope', sans-serif !important;
font-size: 14px; 
border: 1px solid #ccc; 
}

/* .regulator-submit-button {
display: block;
width: 100%;
padding: 10px;
margin-top: 10%;
background: #3f9ba1;
color: #fff;
border: none;
font-family: 'Manrope';
font-size: 14px;
font-weight: 500;
border-radius: 4px;
cursor: pointer;
} */

.form-containerrr {
  position: absolute;
  top: 45px; 
  right: 58px;
  padding: 20px;
  z-index: 3;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 200px;
}

.regulator-submit-button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10%;
  background: #3f9ba1;
  color: #fff;
  border: none;
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
background: #358a90;
}

.dropdown-wrapper .dropdown-options {
  right: 100%; /* Position to the left */
  transform: translateX(-10px);
  margin-top: -67px;
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr)); /* 2-column layout; adjust to 3 if needed */
  /* gap: 10px; Adds space between items */
  max-height: unset; /* Ensure no scrollbar */
  overflow: hidden; /* Hide overflow to prevent scrollbar */
  padding: 10px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 255px;
  margin-right: 4px;
}


.selected-topics {
margin-top: 15px;
padding: 10px;
}

.selected-topics ul {
list-style-type: none;
padding: 0;
margin: 0;
}

.selected-topics li {
margin-bottom: 5px;
}

.custom-menu {
display: none;
position: fixed;
background-color: #fff;
border: 1px solid #ccc;
padding: 10px;
z-index: 100;
}

.custom-menu p {
margin: 0;
padding: 10px 20px;
cursor: pointer;
font-weight: bold;
color: #333;
border-bottom: 1px solid #ccc;
}

.custom-menu p:hover {
background-color: #f9f9f9;
}

.custom-menu p:last-child {
border-bottom: none;
}

.vis-tooltip {
white-space: pre-wrap;
display: inline;
position: absolute;
background-color: #fff8dc; 
border: 1px solid #ffd700; 
padding: 10px; 
font-family: 'Manrope', sans-serif;
}

/* .compliance-dropdown .dropdown-content {
display: none;
position: absolute;
top: 0;
right: 0;
background-color: #f9f9f9;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
z-index: 1;
}

.compliance-dropdown:hover .dropdown-content {
display: block;
margin-top: -5px;
}

.compliance-dropdown .dropdown-content a {
color: black;
padding: 12px 16px;
text-decoration: none;
display: block;
}

.compliance-dropdown .dropdown-content a:hover {
background-color: #f1f1f1;
} */

.compliance-dropdown {
position: relative;
display: inline-block;
}

.compliance-dropdown .dropdown-content {
display: none;
position: absolute;
top: 100%;
left: 0;
background-color: #f9f9f9;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
z-index: 1000;
}

.compliance-dropdown:hover .dropdown-content {
display: block;
}

.compliance-dropdown .dropdown-content a {
color: black;
padding: 12px 16px;
text-decoration: none;
display: block;
}

.compliance-dropdown .dropdown-content a:hover {
background-color: #f1f1f1;
}

.compliance-table {
width: 95%;
border-collapse: collapse;
margin-top: 20px;
}

.compliance-table {
border-collapse: separate;
border-spacing: 0;
border-radius: 10px;
overflow: hidden;
}

.compliance-table th {
background-color: #f2f2f2;
text-align: center;
padding: 10px;
border-bottom: 2px solid #333;
}


.compliance-table tbody tr:nth-child(even) {
background-color: #f2f2f2;
}

.compliance-table tbody tr:nth-child(odd) {
background-color: #ffffff;
}


.compliance-table td {
padding: 30px;
border-bottom: 1px solid #ccc;
}

.compliance-table a {
color: #007bff;
text-decoration: none;
}

.compliance-table a:hover {
text-decoration: underline;
}

.compliance-table {
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.compliance-table th {
background: linear-gradient(to bottom, #f2f2f2, #d9d9d9);
}

.chat-container {
display: flex;
flex-direction: column;
height: 100vh;
background-color: transparent;
width: 100vw;
pointer-events: auto;
}
.chat-container.loading {
pointer-events: none; 
}
.sidebar {
width: 15.7vw;
padding: 16px;
height: 85vh;
position: relative;
/* border-radius: 6px; */
background: #fff;
display: flex;
flex-direction: column;
/* margin-top: 70px;
margin-bottom: 50px;
margin-left: 70px; */
overflow-y: hidden;
border-width: 0px 1px 0px 0px; 
border-style: solid;
border-color: var(--gray, #E6EBF0);
}

/* .sidebar-open {
left: 0; 
} */

.sidebar-closed {
display: none;
}
.sidebar:hover {
overflow-y: auto;
}

.sidebar-header {
text-align: center;
margin-bottom: 20px;
}

.logo {
padding: 8px 8px 0px 5px;
z-index: 2;
max-width: 100%;
height: 27px;
color: var(--unnamed-color-1b88e6);
text-align: left;
font-size: 20px;
font-weight: 600;
font-family: 'Manrope';
/* font: normal normal bold 21px/27px "Red Hat Display", sans-serif; */
letter-spacing: 0px;
color: #4fc2c9;
opacity: 1;
}

.titlee {
padding: 0px 5px 5px 5px;
margin-top: 1px;
font-size: 14px;
color: #fff;
max-width: 100%;
height: 16px;
color: var(--unnamed-color-0c111b);
text-align: left;
font-family: 'Manrope';
font-size: 12px;
font-weight: 500;
/* font: normal normal 500 12px/14px "Red Hat Display", sans-serif; */
letter-spacing: 0px;
color: #20324E;
opacity: 1;
}

.subtitlee {
font-size: 12px;
margin-top: 20px;
color: #000;
font-family: 'Manrope', sans-serif;
}

.recent-chat-label {
margin-top: 10px;
width: 121px;
height: 26px;
text-align: left;
font: normal normal 600 20px/26px ;
letter-spacing: 0px;
color: #0c111b;
opacity: 1 !important;
}

.divider-container {
position: relative;
margin-top: 20px;
}

.divider-below-recent-chat {
border: 0.5px solid #ccc;
}

.dark-mode .sidebar {
background: #fff;
/* display: none; */
}

.bottom-left-section {
position: absolute;
bottom: 0;
left: 0;
display: flex;
align-items: center;
margin-left: 80px;
}

.microphone-button {
font-size: 30px; 
margin-bottom: 10px;
color: #4fc2c9; 
cursor: pointer;
background-color: transparent;
border: none;

}
.bottom-left-text {
max-width: 100%;
height: 18px;
font-size: 14px;
color: #1a1e2d;
font-weight: normal;
text-align: left;
letter-spacing: 0px;
opacity: 1;
margin-right: 5px;
}
.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 8px; 
}

.checkbox-item {
  display: flex;
  align-items: center;
  width: fit-content; 
  white-space: normal; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  cursor: pointer;
}

.checkbox-item input[type="checkbox"] {
  margin-right: 8px; 
}

.checkbox-items-grid {
  display: grid;
  grid-template-columns: repeat(2, 100px); 
  gap: 8px;
}

.bottom-left-logo {
width: 70px;
height: 20px;
opacity: 1;
z-index: 2;
margin-top: 8px;
}


.header-content{
display: flex;
margin-bottom: -10px;
}
.header-text {
display: flex;
flex-direction: column;
justify-content: space-evenly;
align-items: flex-start;
width: 80%;
color: #000;
/* gap:10px; */
margin-top: 15px;
margin-left: 20px;
}
.row {
display: flex;
justify-content: flex-start; 
width: 100%;
flex-wrap: nowrap; 
}
h4{
margin-top: 0;
}

/* .metadata-pair, .regulator-pair {
display: flex;
flex-direction: column;
align-items: flex-start;
margin-bottom: 10px;
width: 13vw;
gap: 5px;
} */
.close-button {
position: absolute !important;
top: -8px;
right: -10px;
}
.metadata-container {
margin: 20px;
font-family: 'Manrope', sans-serif;
width: 100%;
}

.row {
display: flex;
/* justify-content: space-between; */
margin-bottom: 15px;
}

.metadata-pair {
margin-right: 20px;
display: flex;
}
.metadata-pair-count{
width: 8vw;
margin-right: 20px;
display: flex;
align-items: center;
position: relative;
margin-bottom: 10px;
}
.key1{
font-weight: bold;
margin-right: 5px;
font-size: 14px;
color: #000;
}
.value {
color: #333;
}
.table-row1{
display: flex;
}
.table-row2{
display: flex;
}
.section {
border: 1px solid #ddd;
padding: 15px;
width: max-content;
display: flex;
flex-direction: column;
background-color: #f9f9f9;
}

.sub-section {
margin-top: 10px;
}

.sub-section h4 {
margin-bottom: 5px;
font-size: 1.1em;
color: #333;
}
.summary-section{
display: flex;
width: 100%;
gap: 30px;
padding: 12px;
}
.toggle-link {
color: #007bff;
cursor: pointer;
background: none;
border: none;
font-size: 1em;
padding: 0;
}

.toggle-link:hover {
text-decoration: underline;
}

.key{
font-weight: 500;
margin-right: 10px; 
white-space: nowrap; 
font-size: 11px; 
font-family: 'Manrope';
color: #7B818A;
}

.value {
font-weight: 500;
margin-right: 10px; 
white-space: nowrap; 
font-size: 14px;
font-family: 'Manrope';
color: #20324E;
}
.metadata-pair {
position: relative;
margin-bottom: 10px;
}

.metadata-pair .value {
display: inline-block;
max-width: 400px; 
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}
.user-profile-initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px; 
  height: 40px;
  border: 1px solid gray;
  border-radius: 50%;
  /* background-color: #333;  */
  color: #3f9ba1;
  font-size: 16px; 
  margin-left: 10px;
  font-weight: bold;
}

.chip {
  background-color: #E6EBF0; 
  border-radius: 16px; 
  padding: 4px 12px; 
  font-size: 0.9em; 
  color: #333;
  display: inline-block; 
  margin: 0; 
}

.read-chip {
  background-color: #D7EDFF; 
}

.write-chip {
  background-color: #D7EEEF; 
}

.chips-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px; 
}

.metadata-pair .tooltip {
display: none;
position: absolute;
left: 0;
top: 100%;
background: #fff;
border: 1px solid #ccc;
padding: 5px;
z-index: 10;
white-space: normal;
width: max-content; 
box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.metadata-pair:hover .tooltip {
display: block;
}

.regulator-button {
background-color: #3F9BA1; 
color: #FFFFFF; 
font-size: 12px;
padding: 10px ; 
border: none; 
border-radius: 4px; 
cursor: pointer; 
position: absolute;
top: 13px;
right: 85px;
display: inline-block; 
/* margin-top: 15px;  */
align-self: center;
transition: background-color 0.3s ease; 
height: fit-content;

}

.regulator-button:hover {
background-color: #358A90; 
}

.regulator-button:focus,
.regulator-button:active {
outline: none; 
background-color: #2D7B80; 
}
.row-summary{
  padding: 20px;
}
.browse-regulator-button{
background-color: #3F9BA1; 
color: #FFFFFF; 
font-size: 12px;
padding: 10px ; 
border: none; 
border-radius: 4px; 
cursor: pointer; 
display: inline-block; 
/* margin-left: 35%; */
/* margin-top: 15px;  */
align-self: center;
transition: background-color 0.3s ease; 
height: fit-content;
}
.user-tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  top: 40px; 
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  font-size: 12px;
}
.stop-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: none;
  color: #ff0000; 
  font-size: 1.5em;
  cursor: pointer;
}
.user-profile-button:hover .user-tooltip {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s;
}
.browse-header-text {
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
width: 80%;
color: #000;
margin-top: 15px;
margin-left: 20px;
}


.browse-document-key{
font-weight: 800;
margin-right: 10px; 
white-space: nowrap; 
font-size: 19px;
}
.no-data-message {
text-align: center;
margin-top: 67px;
padding: 15px;
border-radius: 5px;
color: #333;
font-size: 17px;
font-weight: bold;
}


.browse-loader-container
{
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
z-index: 1; 
justify-content: center;
align-items: center;
margin-top: 125px;
}
.browse-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-circle {
  animation: spin 2s linear infinite;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top-color: #438ed8;
  height: 50px;
  width: 50px;
  margin-bottom: 8px; /* Spacing between loader and text */
}

.loading-text {
  font-size: 1.2em;
  color: #333;
}


.cancel-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #ff4d4f; /* A cancel/red color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.cancel-button:hover {
  background-color: #ff7875;
}

.browse-divider {
margin-top: 10px;
margin-left: 20px;
margin-right: 10px;
width: 95%;
border: 1px solid var(--unnamed-color-777c83);
border: 1px solid #777c83;
opacity: 0.42;
}




.row-one, 
.row-two,
.row-three {
display: flex;
justify-content: flex-start;  
width: 100%;
flex-wrap: nowrap; 
margin-bottom: 10px;
}

.regulator-pair {
display: flex;
align-items: center;
width: 200px; 
margin-right: 140px; 
}

.circulars-pair {
display: flex;
align-items: center;
width: 250px; 
margin-right: 248px; 
}

.master-pair {
display: flex;
align-items: center;
width: 180px; 
margin-right: 100px; 
}

.master-directions-pair {
display: flex;
align-items: center;
width: 240px; 
margin-right: 33px; 
}

.latest-circular-pair {
display: flex;
align-items: center;
width: 300px; 
margin-right: 122px; 
}

.latest-circular-date-pair {
display: flex;
align-items: center;
width: 350px; 
margin-right: 160px; 
}
.circular-number-pair{
display: flex;
align-items: center;
width: 350px; 
margin-right: 160px; 
}

.ask-question-text {
text-align: left;
color: #0c111b;
font: normal normal 600 20px/26px;
letter-spacing: 0px;
opacity: 1;
/* font-weight: bold;  */
margin-left: 20px;
/* margin-top: -30px; */
font-size: 22px;
user-select: none;
}

.button-containerr {
display: flex;
/* flex-wrap: wrap; */
}
.button-containeri{
display: flex;
flex-wrap: wrap;
}
.button-card {
flex: 0 0 calc(23.33% - 4px);
margin-right: 10px;
margin-bottom: 10px;
/* border: 1px solid #ccc; */
border-radius: 5px;
overflow: hidden;
}

.button-card-content {
padding: 10px;
background-color: #d6e2eb;
}

.button-card button {
background-color: #4fc2c9;
border: none;
color: white;
padding: 8px 16px;
border-radius: 5px;
cursor: pointer;
display: block;
margin-bottom: 10px;
}

.button-card button:hover {
background-color: #ddd;
}

.button-card p {
margin: 0;
}

.loader-container {
/* position: absolute; */
top: 0;
left: 0;
width: 100%;
height: 100%;
display: flex;
z-index: 1; 
flex-direction: column;
justify-content: center;
align-items: center;
}

.saving {
filter: blur(5px);
pointer-events: none; 
}
.loader-containerr{
align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  justify-content: center;
  left: 0;
  top: 0;
  filter: none !important;
  width: 100%;
  z-index: 1;
}
.loaderr {
border: 5px solid #f3f3f3;
border-top: 5px solid #121314;
border-radius: 50%;
width: 70px;
height: 70px;
animation: spin 2s linear infinite;
}
/* .loader {
border: 5px solid #f3f3f3;
border-top: 5px solid #438ed8;
border-radius: 50%;
width: 50px;
height: 50px;
animation: spin 2s linear infinite;
} */
.formloader {
border: 5px solid #f3f3f3;
border-top: 5px solid #438ed8;
border-radius: 50%;
z-index: 2;
margin-left: 59px;
width: 50px;
height: 50px;
position: relative;
animation: spin 2s linear infinite;
}
.form-loader-container{
position: relative;
z-index: 2;
margin-top: -46px;
}
@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
.new-chat-button {
  background: #3F9BA1;
  border: none;
  border-radius: 16px;
  color: #fff;
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  cursor: pointer;
  height: 41px;
  opacity: 1;
}
.clear-chat-button {
  border: 1px solid gray;
  cursor: pointer;
  height: 41px;
  font-size: 14px;
  font-weight: 500;
  color: #3F9BA1;
  font-family: 'Manrope';
  margin-left: 30px;
  margin-bottom: 7px;
  justify-content: space-around;
  border-radius: 16pc;
  opacity: 1;
  width: 10%;
}
.header-buttons {
display: flex;
justify-content: flex-end;
margin-top: 15px;
}

.mobile-menu {
display: none;
}
.hamburger-button {
position: fixed;
top: 10px;
left: 10px;
background-color: transparent;
border: none;
cursor: pointer;
}

.hamburger-buttonn {
position: fixed;
top: 10px;
left: 10px;
background-color: transparent;
border: none;
cursor: pointer;
}

.hamburger-iconn {
width: 30px;
height: 2px;
background-color: #0b0a0a; 
margin: 6px 0;
transition: 0.4s;
}


.divider {
margin-top: 10px;
margin-left: 20px;
margin-right: 10px;
width: 95%;
border: 1px solid var(--unnamed-color-777c83);
border: 1px solid #777c83;
opacity: 0.42;
}

.save-button {
margin-bottom: 16px;
background-color: #28a745;
color: white;
border: none;
border-radius: 4px;
padding: 8px 16px;
cursor: pointer;
}

.chat-history {
flex-grow: 1;
overflow-y: auto;
/* border-top: 1px solid #ccc; */
padding-top: 8px;
display: flex;
flex-direction: column;
font-size: 20px;
}
.chat-history-entry {
display: flex;
align-items: center;
justify-content: space-between;
padding: 8px;
font-family: 'Manrope';
font-weight: 400;
font-size: 14px;
text-align: start;
background-color: transparent;
/* border-top: 1px solid #777C83; */
border-bottom: 1px solid #777c83;
/* border-radius: 4px; */
color: rgb(13, 13, 13);
font-size: 14px;
}
.chat-history-entry:hover {
background-color: #635e5e;
/* background-color: #3f9ba1;; */
}
.chat-history-entry.active {
background-color: #635e5e;
color: #fff;
cursor: pointer;
}

.delete-button {
background-color: rgb(62, 59, 59);
color: #fff;
border: none;
border-radius: 4px;
padding: 4px 8px;
cursor: pointer;
display: flex;
align-items: center;
}

.delete-button i {
margin-right: 4px;
}
.chat-content {
/* flex-grow: 1; */
border-width: 1px 1px 1px 1px; 
border-style: solid;
border-color: var(--gray, #E6EBF0);
position: relative;
width: 82vw;
/* height: 92vh; */
display: flex;
flex-direction: column;
transition: width 0.3s ease;
color: #fff;
background: #e6ebf0;;
}
.chat-content-browse {
border-width: 1px 1px 1px 1px; 
border-style: solid;
border-color: var(--gray, #E6EBF0);
position: relative;
width: 100vw;
display: flex;
flex-direction: column;
transition: width 0.3s ease;
color: black;
background: #e6ebf0;;
}

.modal {
display: none; 
position: fixed; 
z-index: 1; 
left: 0;
top: 0;
width: 100%;
height: 100%; 
overflow: auto; 
background-color: rgba(0, 0, 0, 0.4); 
}


.modal-content {
background-color: #fefefe;
margin: 15% auto;
padding: 20px;
border: 1px solid #888;
width: 80%; 
}

.close:hover,
.close:focus {
color: black;
text-decoration: none;
cursor: pointer;
}

.metadatamodal {
display: block;
position: fixed;
z-index: 1;
left: 0;
top: 0;
width: 100%;
height: 100%;
overflow: auto;
background-color: rgb(0, 0, 0);
background-color: rgba(0, 0, 0, 0.4);
}

.metadatamodal-content {
background-color: #fefefe;
margin: 15% auto;
padding: 20px;
border: 1px solid #888;
width: 25%;
}

.metadataclose {
color: #aaa;
float: right;
font-size: 28px;
font-weight: bold;
}

.metadataclose:hover,
.metadataclose:focus {
color: black;
text-decoration: none;
cursor: pointer;
}
.Commandmodal {
width: 300px;
box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
border-radius: 4px;
position: absolute;
background-color: #fff;
border: 1px solid #ccc;
padding: 10px;
z-index: 1000;
}

.chat-content.sidebar-closed {
width: calc(130% - 50px); 
/* margin-left: 10%;
margin-right: -13%; */
}


@media screen and (min-width: 749px) and (max-width: 1600px) {
.chat-content.sidebar-closed {
  width: calc(130% - 50px); 
  /* margin-left: 20%;
  margin-right: -22%;  */
}
/* .chat-content{
  width:70%;
} */
.regulator-pair {
  margin-right: 43px;
}
.circulars-pair {
margin-right: 79px;
} 
.latest-circular-pair {
margin-right: 72px;
}
.master-directions-pair {
margin-right: 12px;
}
.latest-circular-pair {
margin-right: 80px;
}
} 
.chat-details{
background-color: #fff;
height: 92vh;
display: flex;
overflow: hidden;
flex-direction: column;
margin: 10px;
}
.chat-messages {
flex-grow: 1;
/* margin-top: -14px; */
padding: 10px;
overflow-y: auto;
display: flex;
flex-direction: column;
align-items: flex-start;
overflow-x: hidden;
width: 98%;
}
/* .chat-messages:hover {
overflow-y: auto;
} */

.message {
padding: 8px;
margin: 10px 0;
border-radius: 8px;
max-width: 800px;
align-self: flex-start;
/* align-items: center; 
justify-content: center;  */
/* margin-left: 200px; */
width: 150%;
}

/* .user-message {
background-color: #94eca3;
color: #242222;
font-size: 20px;

}

.bot-message {
background-color: #f0f0f0;
color: #000;
align-self: flex-start; 
font-size: 20px;


} */


.file-upload{
  display: flex;
  width: 50%;
  align-self: center;
  gap: 15px;
}
.compare-button {
  width: 20%;
  align-self: center;
  padding: 12px;
  background-color: #3f9ba1;
  color: #ffffff;
  font-weight: 500;
  line-height: 20px;
  font-family: 'Manrope', sans-serif; 
  border: none;
  border-radius: 40px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.user-message {
color: #100f0f;
background-color: transparent;
font-size: 16px;
padding: 4px 8px;
border-radius: 10px;
display: flex;
flex-direction: column;
width: 100%;
max-width: 1000%;
align-self: flex-start;
}

.bot-message {
background-color: transparent;
color: #000;
font-size: 16px;
padding: 4px 50px;
border-radius: 10px;
/* display: flex; */
width: 98%;
max-width: 100%;
align-self: flex-start;
}

.user-content {
display: flex;
align-items: center;
margin-right: 10px;
}
.user-text {
max-width: 120%;
font-weight: 600;
font-family: 'Manrope';
font-size: 16px;
/* white-space: nowrap;  */
overflow: hidden;
text-overflow: ellipsis;
}
.user-icon {
/* position: absolute; */
width: 40px;
height: 40px;
display: flex;
align-items: center;
justify-content: center;
opacity: 1;
margin-right: 10px;
margin-bottom: auto;
}
.bottom-left-secton {
position: absolute;
bottom: 0;
right: 0;
display: flex;
align-items: center;
margin-right: 80px;
}
.bottom-left-imge {
width: 60px;
height: 25px;
opacity: 1;
margin-top: 3px;
}

.user-icon img {
width: 24px;
height: 24px;
background: transparent;
object-fit: cover;
}

/* .bot-icon::before {
content: '\1F9D1'; 
font-size: 20px;
margin-right: 5px;
} */

.bot-content {
display: flex;
align-items: flex-start;
margin-right: 10px;
width: 100%;
}
.bot-text {
max-width: 100%;
width: 88%;
overflow: hidden;
text-overflow: ellipsis;
margin-top: 5px;
}
.bot-icon {
background: #3F9BA1;
border-radius: 50%;
display: flex;
align-items: center;
/* background: #1b88e6; */
border-radius: 50%;
display: flex;
height: 0px;
justify-content: center;
margin-bottom: auto;
margin-right: 10px;
opacity: 1;
width: 0px;
padding: 17px;
margin-top: -5px;
}

.bot-icon img {
width: 35px;
height: 35px;
background: transparent;
object-fit: cover;
}

.message-divider {
/* width: 100%;
height: 1px;
background-color: #0f0e0e; 
margin: 10px 0; */
/* display: flex; */
}

.bot-divider {
width: auto;
background-color: #000;
margin-top: 40px;
align-self: center;
flex-grow: 1;
}

.pdf-table {
width: 100%;
border-collapse: collapse;
margin-top: 10px;
}

.pdf-table th,
.pdf-table td {
border: 1px solid #ddd;
padding: 8px;
text-align: center;
}

.pdf-table th {
background-color: #f2f2f2;
}

.pdf-table tr:hover {
background-color: #f5f5f5;
}


.input-box-container {
display: flex;
align-items: center;
justify-content: center;
width: 100%;
/* margin-left: 10px; */
margin-bottom: 10px;
}
.input-content{
align-items: center;
display: flex;
  width: 70%;
  align-items: center;
}

.bot-response {
/* margin-top: 20px; */
font-family: 'Manrope';
color: #20324E;
font-size: 14px;
}


.headClasss {
bottom: 10px;
  color: gray;
  font-size: 25px;
  font-weight: 600;
  position: relative;
}

.circular-metadata {
margin-bottom: 20px; 
}

.metadata-part {
font-size: 20px; 
/* margin-bottom: 10px;  */
}

.button-containerr {
margin-top: 20px;
}

.action-button {
background-color: #4fc2c9; 
color: #fff; 
border: none;
border-radius: 5px;
padding: 10px 20px;
margin-right: 10px; 
cursor: pointer;
transition: background-color 0.3s ease;
}

.action-button:hover {
background-color: #518083; 
}
.system-message {
text-align: center;
font-style: italic;
color: #666;
background-color: #f0f0f0;
padding: 10px;
border-radius: 4px;
margin: 10px 0;
}

.system-content {
display: flex;
justify-content: center;
align-items: center;
width: 100%;
}

.system-text {
font-family: 'Manrope', sans-serif;
font-size: 0.9em;
}

.suggestions-container {
position: absolute;
left: 11.5%;
bottom: 12%;
width: 60%;
max-height: 50%;
overflow-y: auto;
/* border: 1px solid #ccc; */
border-radius: 4px;
background-color: #d6d6d6;
z-index: 1;
}
.selected-suggestion {
background-color: #f0f0f0; 
}

.suggestions-list {
list-style-type: none;
padding: 0;
margin: 0;
}

.suggestion-item {
padding: 10px;
cursor: pointer;
border-bottom: 1px solid #ccc;
}

.suggestion-item:last-child {
border-bottom: none;
}

.suggestion-item:hover {
background-color: #d5f4f7;
}
.chat-placeholder {
display: flex;
align-self: flex-start;
text-align: left;
line-height: 1.5;
padding: 10px; 
font-size: 15px;
color: #5e5a5a;
}

.chat-placeholder.visible {
/* display: contents; */
}
.pdf-table .fa-arrow-down {
font-size: 16px;
color: #007bff;
cursor: pointer;
}
.chat-placeholder:not(.visible) {
display: none;
}
.containerr {
flex-direction: column;
align-items: center; 
position: relative;
display: inline-block;
margin: auto;
/* padding: 20px;  */
max-width: 100%; 
box-sizing: border-box;
}

.row1, .row2 {
display: flex;
justify-content: center; 
/* margin: 10px 0;  */
}
.header-tooltip {
display: none; 
position: absolute; 
left: 0;
top:100%;
/* transform: translateX(-50%); */
background-color: white; 
color: black; 
padding: 8px;
border-radius: 4px;
/* white-space: nowrap; */
z-index: 1000;
box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
width: 300px; 
}

.header-tooltip-compare {
  display: none; 
  position: absolute; 
  right: 0;
  top:100%;
  /* transform: translateX(-50%); */
  background-color: white; 
  color: black; 
  padding: 8px;
  border-radius: 4px;
  /* white-space: nowrap; */
  z-index: 1000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 300px; 
  }

.box:hover .header-tooltip {
display: block;
}
.box:hover .header-tooltip-compare {
  display: block;
  }

.box h4 {
margin: 0; 
display: inline-block; 
}

.box p {
margin: 0;
font-size: 0.8em; 
}

.box {
padding: 6px;
margin: 6px; 
width: 10vw;
border-radius: 30px;
background: #E6EBF0;
display: flex;
flex-direction: row;
align-items: center;
box-sizing: border-box;
border: none;
justify-content: space-between;
position: relative; 
font-size: 0.9em;
cursor: pointer;
text-align: left;
}

.icon {
width: 20px;
height: 20px;
}

.info-icon{
Width:18.33px;
Height:18.33px;
color: #727784;
}

.box code {
background-color: #f0f0f0;
padding: 3px 6px;
border-radius: 3px;
}

.box ul {
margin-top: 10px;
padding-left: 20px;
}

.box ul li {
color: #666;
font-size: 1rem;
margin-bottom: 5px;
}
.toggle-content{
background: white;
border: 1px solid #e6ebf0;
display: flex;
justify-content: flex-end;
padding: 10px;
}
.toggle-link {
/* margin-top: 8px; */
background: none;
margin-left: 7%;
border: none;
color: #3F9BA1;
cursor: pointer;
text-decoration: underline;
}

@media screen and (max-width: 767px) {
.box {
  flex-basis: calc(50% - 40px); 
}
}

@media screen and (max-width: 479px) {
.box {
  flex-basis: calc(100% - 40px); 
}
}

.input-box {
/* flex-grow: 1;
padding: 8px;
border: 1px solid #ccc;
border-radius: 4px;
color: #131111; */
/* background-color: #100f0f; */
/* font-size: 14px;
margin-left: 20px;
margin-bottom: 10px; */

}

.send-button {
background: var(--unnamed-color-1b88e6) 0% 0% no-repeat padding-box;
background: #3F9BA1;
color: rgb(24, 23, 23);
border: none;
padding: 0;
border-radius: 50%;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
width: 38px;
height: 35px;
margin-left: -50px;
margin-bottom: 10px;
}
.send-button-image{
width: 24px;
height: 24px;
}
.send-icon {
width: 24px;
height: 24px;
}
.user-profile-dropdown {
position: absolute;
background-color: #f9f9f9;
width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
z-index: 2;
right: 0;
top: 100%;
/* display: none; */
}

.user-profile-dropdown div {
list-style: none;
text-decoration: none;
padding: 0;
margin: 0;
}

.user-profile-dropdown div {
padding: 12px 16px;
text-decoration: none !important;
display: block;
color: #333;
cursor: pointer;
}

.user-profile-dropdown div:hover {
background-color: #ddd;
}

.user-profile-dropdown.active {
display: block;
}

.user-profile-dropdown div.active {
background-color: #3498db;
color: #fff;
}

.user-profile-container {
position: absolute;
/* position: relative; */
align-self: flex-end;
top: 6px;
right: 0;
margin-top: 5px;
display: flex;
align-items: center;
margin-bottom: 10px;
}

.user-profile-button {
/* width: 40px; */
height: 40px;
background: transparent;
display: flex;
align-items: center;
justify-content: center;
opacity: 1;
margin-right: 10px;
cursor: pointer;
border: none;
}

.user-profile-image {
width: 24px;
height: 24px;
background: transparent;
object-fit: cover;
}

.logout-button {
background-color: #dc3545;
color: white;
border: none;
padding: 5px 10px;
border-radius: 5px;
cursor: pointer;
}

.light-mode {
/* background-color: rgb(213, 206, 206); */
color: #151414;
}
.light-mode .chat-content {
background: #E6EBF0;
}
.light-mode .titlee {
padding: 0px 5px 5px 5px;
margin-top: 1px;
font-size: 14px;
color: #fff;
max-width: 100%;
height: 16px;
color: var(--unnamed-color-0c111b);
text-align: left;
font-family: 'Manrope';
font-size: 12px;
font-weight: 500;
/* font: normal normal 500 12px/14px "Red Hat Display", sans-serif; */
letter-spacing: 0px;
color: #20324E;
opacity: 1;
}
.clickable-link {
color: blue;
text-decoration: underline;
cursor: pointer;
background: none;
border: none;
padding: 0;
font: inherit;
}
.light-mode .subtitlee {
color: #000;
}
.light-mode .sidebar {
background: #fff;
/* border: 1px solid #afb3b9; */
}

.light-mode .divider {
margin-top: 10px;
margin-left: 20px;
margin-right: 10px;
width: 95%;
border: 1px solid var(--unnamed-color-777c83);
border: 1px solid #777c83;
opacity: 0.42;
}
.light-mode .chat-history-entry {
/* background-color: #494848; */
background-color: transparent;
font-family: 'Manrope';
font-weight: 400;
font-size: 14px;
color: rgb(13, 13, 13);
/* border-top: 1px solid #777C83; */
/* border-bottom: none; */
border-left: none;
border-right: none;
/* border-radius: 4px; */
/* border: none; */
}
.light-mode .chat-history-entry:hover {
background-color: #635e5e;
cursor: pointer;
}
.light-mode .chat-history-entry.active {
background-color: #635e5e;
color: #ebe5e5;
cursor: pointer;
}
.light-mode .delete-button {
background-color: white;
color: #000;
}
.light-mode .send-button {
/* background-color: #1e1e1e; */
/* color: rgb(201, 196, 196); */
}
.light-mode .chat-history {
/* border-top: 1px solid #111111; */
}

.input-box {
height: 25px;
/* flex-grow: 1; */
padding: 12px 12px 12px 35px;
border: 1px solid #ccc;
border-radius: 16px;
color: #0b0b0b;
width: 100%;
background: #F0F3F6;
font-size: 14px;
margin-left: 20px;
margin-bottom: 10px;
}
.input-box:disabled {
background-color: #f0f0f0; 
border-color: #737171; 
color: #999; 
cursor: not-allowed; 
opacity: 0.7; 
}
.mode-label {
position: absolute;
display: flex;
align-items: center;
justify-content: center;
width: 50%;
height: 100%;
font-size: 12px;
color: #fff;
transition: 0.4s;
}

.mode-switch {
position: relative;
display: inline-block;
width: 7rem;
height: 35px;
margin-right: 30px;
}

.mode-switch input {
display: none;
}

.date-picker-container {
display: flex;
justify-content: center;
gap: 30px;
width: 75vw;
}

.date-picker {
padding: 8px;
border: 1px solid #ccc;
border-radius: 4px;
font-size: 16px;
width: 24vw;
height: 30px;
}
.date-picker-wrapper {
position: relative;
display: inline-block;
/* width: 100%; */
}

.date-picker {
/* width: 100%;
padding-right: 30px;  */
}
.browse_buttons{
display: flex;
justify-content: center;
gap: 10%;
align-items: center;
width: 75vw;
}
.calendar-icon {
position: absolute;
right: 5%;
top: 50%;
transform: translateY(-50%);
pointer-events: none; 
}
.arrow-icon{
position: absolute;
right: 4%;
transform: translateY(-50%);
pointer-events: none; 
height: auto;
  width: auto;
  max-height: 60%;
  max-width: 20px;
}
.arrow-iconn{
position: absolute;
left: 33%;
top: 34%;
}
.content-dropdown{
display: flex;
width: 60%;
position: relative;
align-items: center;
}
.date-picker:focus {
border-color: black;
outline: none; 
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: rgb(71, 67, 67);
transition: 0.4s;
border-radius: 20px;
}

.slider:before {
position: absolute;
content: "";
height: 30px;
width: 16px;
left: 40px;
bottom: 2px;
background-color: rgb(212, 208, 208);
transition: 0.4s;
border-radius: 50%;
}

input:checked + .slider {
background-color: #4690de;
}

input:checked + .slider:before {
transform: translateX(20px);
}

.mode-label {
position: absolute;
display: flex;
align-items: center;
justify-content: center;
width: 100px;
height: 30px;
font-size: 12px;
transition: 0.4s;
}

.dark-label {
left: 40px;
}

.light-label {
right: 40px;
}

.chat-history::-webkit-scrollbar {
width: 10px;
}

.chat-history::-webkit-scrollbar-thumb {
background-color: rgba(0, 0, 0, 0.3);
border-radius: 5px;
}

.chat-history:hover::-webkit-scrollbar-thumb {
background-color: rgba(0, 0, 0, 0.5);
}

.chat-container::-webkit-scrollbar {
width: 0;
background-color: transparent;
}

.chat-container::-webkit-scrollbar-thumb {
background-color: rgba(0, 0, 0, 0.3);
border-radius: 5px;
}

.chat-container:hover::-webkit-scrollbar-thumb {
background-color: rgba(0, 0, 0, 0.5);
}

.App {
text-align: center;
font-family: 'Manrope', sans-serif;
}

h1 {
color: #333;
}

.table-container {
max-width: 600px;
margin: 0 auto;
}

table {
width: 100%;
border-collapse: collapse;
}

/* th,
td {
border: 1px solid #ccc;
padding: 10px;
} */



.dropdown {
position: absolute;
top: 530px;
left: 540px;
width: 7%;
background-color: #fff;
border: 1px solid #ccc;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
z-index: 1;
max-height: 150px;
overflow-y: auto;
}

.dropdown-option {
padding: 5px;
cursor: pointer;
transition: background-color 0.2s ease;
}

.dropdown-option:hover {
background-color: #f0f0f0;
}

@media screen and (max-width: 767px) {
.logo {
  margin-left: 0px;
}
.light-mode .titlee {
  margin-left: 0px;
}
.sidebar {
  width: 20%;
  display: none;
}
.ask-question-text {
  text-align: left;
  color: #0c111b;
  font: normal normal 600 20px/26px;
  letter-spacing: 0px;
  opacity: 1;
  /* font-weight: bold;  */
  margin-left: 20px;
  /* margin-top: -30px; */
  font-size: 20px;
  margin-top: -100px;
  user-select: none;
}
.recent-chat-label {
  margin-top: 0px;
  width: 70px;
  height: 20px;
  text-align: left;
  font: normal normal 600 10px/16px;
  letter-spacing: 0px;
  color: #0c111b;
  opacity: 1 !important;
}

.divider-container {
  position: relative;
  margin-top: 10px;
}

.divider-below-recent-chat {
  border: 0.5px solid #ccc;
}
.divider1 {
  margin-top: 275px;
}
.new-chat-button {
  width: 90px;
  height: 30px;
  /* background: transparent linear-gradient(270deg, var(--unnamed-color-1b88e6) 0%, #1778CB 100%) 0% 0% no-repeat padding-box; */
  background: #3F9BA1;
  border-radius: 6px;
  font-family: 'Manrope';
  opacity: 1;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  font-size: 10px;
}

.clear-chat-button {
  width: 90px;
  height: 30px;
  color: #1b88e6;
  font-family: 'Manrope';
  opacity: 1;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 10px;
}
/* Styles for hamburger menu button */
.hamburger-buttonn {
background: transparent;
border: none;
cursor: pointer;
display: flex;
flex-direction: column;
justify-content: space-around;
padding: 0;
width: 30px; 
height: 20px; 
}

.hamburger-iconn {
background-color: #000; 
height: 3px; 
width: 100%; 
}


.hamburger-buttonn.open .hamburger-iconn:nth-child(1) {
transform: translateY(8px) rotate(45deg);
}

.hamburger-buttonn.open .hamburger-iconn:nth-child(2) {
opacity: 0;
}

.hamburger-buttonn.open .hamburger-iconn:nth-child(3) {
transform: translateY(-8px) rotate(-45deg);
}

.hamburger-button {
  position: fixed;
  top: 14px;
  left: auto;
  right: 70px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.header-buttons {
  display: flex;
  margin-top: 45px;
  flex-direction: row;
  justify-content: flex-start;
}

.hamburger-icon {
  width: 26px;
  height: 2px;
  background-color: #0b0a0a;
  margin: 6px 0;
  transition: 0.4s;
}

.hamburger-button.open .hamburger-icon:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.hamburger-button.open .hamburger-icon:nth-child(2) {
  opacity: 0;
}

.hamburger-button.open .hamburger-icon:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.mobile-menu {
  display: flex;
}
.sidebar-content {
  width: 73%;
  /* background-color: #252424; */
  padding: 16px;
  /* border-right: 1px solid #ccc; */
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  margin-top: 66px;
  margin-bottom: 50px;
  margin-left: 10px;
  /* background: rgba(251, 248, 248, 0.3); */
  border: 1px solid var(--unnamed-color-777c83);
  /* background: #FFFFFF 0% 0% no-repeat padding-box; */
  border: 1px solid #abb2ba;
  border-radius: 22px;
  /* opacity: 0.3; */
  position: absolute;
  z-index: 2;
  background: white;
}
.sidebar-header {
  text-align: center;
}

.chat-history {
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.user-profile {
  text-align: left;
  display: none;
}

.user-profile-button {
  width: 100%;
  margin-bottom: 10px;
  height: 44px;
}

.user-profile-content {
  display: flex;
  flex-direction: column;
  display: none;
}
.user-profile-image {
  width: 34px;
  height: 28px;
}

.mode-switch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
}

.slider {
  width: 65px;
  display: none;
}
.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 12px;
  left: 12px;
  bottom: 6px;
  background-color: rgb(212, 208, 208);
  transition: 0.4s;
  border-radius: 50%;
  display: none;
}
.chat-content {
  position: relative;
  width: 120%;
  display: flex;
  flex-direction: column;
  color: #E6EBF0;
  border: 1px solid #777c83;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-777c83);
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #c1c6cb;
}
.mode-label {
  font-size: 10px;
  margin: 0;
}
.dark-label {
  left: 0px;
}

.light-label {
  right: 0px;
}

.logout-button {
  width: 100%;
}

/* .chat-container {
  padding: 10px; 
} */

.message {
  width: 90%;
  margin: 10px auto;
  font-size: 16px;
}

.input-container {
  width: 100%;
}

.input-box {
  width: 70%;
}

.send-button {
  width: 12%;
  font-size: 10px;
  text-align: center;
  justify-content: center;
  margin-left: 5px;
  padding-left: 0%;
}

.user-icon::before,
.bot-icon::before {
  font-size: 20px;
}
.header-text {
  margin-top: 0px;
}
.light-mode .divider {
  margin-left: 6px;
}
.chat-messages{
  overflow-y: scroll;
  overflow-x: hidden;
}

}

.css-y599qu {
background: linear-gradient(180deg, #f2f2f2, #d9d9d9e3);
}

::-webkit-scrollbar {
width: 10px;
height: 5px;
}

::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey;
border-radius: 10px;
}

::-webkit-scrollbar-thumb {
background: #cccccc;
border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
background: #c5cad2cc;
}

.headClass {
font-size: 23px;
color: gray;
font-weight: 600;
position: relative;
bottom: 10px;
}
.circularStyle {
white-space: inherit !important;
overflow: visible !important;
flex-wrap: wrap;
overflow-wrap: anywhere !important;
line-height: 1;
font-size: 13px;
}

.message-container {
position: fixed;
top: 19px; 
right: 80px; 
width: 300px; 
height: 30px;
overflow: hidden;
display: flex;
align-items: center;
justify-content: center;
/* border: 1px solid #ccc; */
}

.welcome-message-container {
font-size: 16px;
white-space: nowrap;
animation: marquee 10s linear infinite;
}


@keyframes marquee {
0% { transform: translateX(100%); }
100% { transform: translateX(-100%); }
}

.form-container {
display: flex;
flex-direction: column;
align-items: center;
/* margin: auto;  */
padding: 20px;
border: 1px solid #ccc;
border-radius: 5px;
margin-top:-9px;
width: 90%;
border-collapse: collapse;
max-width: 800px;
margin-left: 48px;
}


.form-action {
flex: 1;
margin-right: 10px;
width:80%;
height: auto;
}

.form-comment {
flex: 1;
margin-right: 10px; 
width:80%;
height: auto;
}

label {
display: block;
margin-bottom: 5px;
font-family: 'Manrope' !important;
/* margin-top: 7px; */
}
.css-yb0lig {
font-family: 'Manrope', sans-serif !important;
}
.select{
font-family: 'Manrope' !important;
}
textarea {
width: calc(100% - 8px) !important; 
padding: 8px !important;
border: 1px solid #ccc !important;
border-radius: 5px o !important;
font-size: 14px !important;
margin-bottom: 13px !important;
}

textarea {
resize: vertical; 
}

textarea:focus {
border-width: 2px !important; 
border-color: black !important; 
}


.form-submit-button {
margin: 20px auto; 
background-color:#4fc2c9;
color: #fff;
padding: 10px 20px;
border: none;
border-radius: 5px;
font-size: 16px;
cursor: pointer;
width: 80%;
}

.submit-button:hover {
background-color: #4fc2c9;
}

.form-submit-button:hover {
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}


.comment-btn {
width: 30px;
height: 30px;
cursor: pointer;
}

.message-actions {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  margin-right: 91px;
}

.icon-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
  padding: 8px;
  cursor: pointer;
  margin-left: 8px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.icon-button:first-child {
  margin-left: 0;
}

/* Active state for Thumbs Up button - this applies to the icon, not the button background */
.icon-button.thumbs-up-active .thumbs-up-icon {
  color: #2EBDD1;  /* Blue color for the icon */
}

/* Active state for Thumbs Down button - this applies to the icon, not the button background */
.icon-button.thumbs-down-active .thumbs-down-icon {
  color: #2EBDD1;  /* Blue color for the icon */
}

/* Hover effect for Thumbs Up button - applies shadow above the icon and lifts it */
.icon-button .thumbs-up-icon:hover {
  transform: translateY(-4px); /* Lift the icon upwards */
}

/* Hover effect for Thumbs Down button - applies shadow above the icon and lifts it */
.icon-button .thumbs-down-icon:hover {
  transform: translateY(-4px); /* Lift the icon upwards */
}

/* Hover effect for .icon-button-stream */
.icon-button-stream:hover {
  opacity: 0.8;
}

/* Style for liked .icon-button-stream */
.icon-button-stream.liked {
  color: #007BFF; /* Blue color for liked state */
}


/* Style for .icon-button-stream when not liked and hovered */
.icon-button-stream:not(.liked):hover {
  color: #666; /* Gray color on hover when not liked */
}

.required {
  color: red;
  margin-left: 5px;
}

input:invalid, textarea:invalid {
  border: 2px solid red;
}

input:focus:invalid, textarea:focus:invalid {
  outline: 2px solid red;
}

.modal-overlay-feedback {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #54505042;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
}

.modal-content-feedback {
  background-color: #fff;
  padding: 20px;
  border-radius: 25px;
  width: 437px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001; 
  position: relative;
}

.modal-content-feedback h2 {
  margin-top: 0;
}

.modal-content-feedback p {
  margin: 10px 0;
}

.modal-content-feedback textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc; 
  border-radius: 4px; 
  padding: 10px; 
  box-sizing: border-box; 
}

.modal-content-feedback textarea:focus {
  border-color: #1b88e6; 
  outline: none; 
}

.modal-buttons-feedback {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-top: 22px;
}

.modal-buttons-feedback button {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  font-size: 13px;
  border-radius: 10px;
}

.modal-buttons-feedback button:first-child {
  background-color: #ccc;
}

.modal-buttons-feedback button:last-child {
  background-color: #08b9c4;
  color: #fff;
}

.feedback-note {
  font-size: 12px;
  color: #555;
  margin-top: 10px;
}

.feedback-note a {
  color: #1b88e6;
  text-decoration: none;
}

.feedback-note a:hover {
  text-decoration: underline;
}


.dislike-dropdown-feedback {
  width: 100%;
  padding: 5px;
  margin-left: 0px;
  margin-top: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc; 
  border-radius: 4px; 
  box-sizing: border-box; 
}

.label-feed {
  margin-left: 0px;
}

.icon-button-stream:hover {
  opacity: 0.8;
}

.icon-button-stream.liked {
  color: #007BFF; 
}


.icon-button-stream:not(.liked):hover {
  color: #666;
}