.audit-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  padding: 1rem;
}

.audit-header {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff; 
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}
.audit-header {
  background-color: white !important;
}

.audit-header-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.audit-header-filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
   align-items: center;
}

.audit-time-filters {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 200px;
}

.audit-time-filter-btn {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.audit-time-filter-btn:hover {
  background-color: #0056b3;
}

.audit-dropdown-filters select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

input[type="date"] {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.audit-filter-button {
  background-color: #21b2c5;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  width: 192px;
  height: 52px;
  margin-top: -3px;
}

.audit-filter-button:hover {
  background-color: #077894;
  transform: scale(1.05);
}

.audit-filter-button:active {
  background-color: #1cb6ca;
  transform: scale(0.95);
}

.audit-filter-button:disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .audit-filter-button {
      font-size: 12px;
      padding: 8px 16px;
      width: 160px;
      height: 48px;
  }

  .audit-time-filters {
      font-size: 14px;
      padding: 8px;
  }
}

@media (max-width: 480px) {
  .audit-filter-button {
      font-size: 12px;
      padding: 6px 12px;
      width: 140px;
      height: 44px;
  }

  .audit-time-filters {
      font-size: 12px;
      padding: 6px;
  }
}

.audit-summary-cards {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.audit-summary-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  text-align: center;
  flex: 1;
}

.audit-summary-card-title {
  font-size: 16px;
  color: #555;
  margin-bottom: 0.5rem;
}

.audit-summary-card-value {
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

.audit-main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.audit-charts-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.audit-chart-column {
  flex: 1;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.audit-logs-section {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.audit-user-profile-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: -20px;
  right: -286px;
  top: 6px;
  position: relative;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}

.audit-user-profile-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.time-filter-container {
  position: relative;
  width: 300px;
  font-family: Arial, sans-serif;
}

.time-filter-box {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 18px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: space-between;
}

.time-filter-box:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.time-filter-box:focus {
  border-color: #1976d2;
  outline: none;
}
.time-filter-box .placeholder {
  color: rgba(0, 0, 0, 0.54); 
}


.clear-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #888;
  margin-left: 10px;
}

.clear-button:hover {
  color: #333;
}

.time-filter-dropdown {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  position: absolute;
  top: 51px;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 4px;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  font-size: 34px;
}

.close-button:hover {
  color: #333;
}

.dropdown-content {
  display: flex;
  padding: 10px;
}

.time-filter-menu {
  border-right: 1px solid #ddd;
  width: 120px;
  padding-right: 20px;
}

.time-filter-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.time-filter-option {
  padding: 10px;
  cursor: pointer;
}

.time-filter-option:hover, .time-filter-option.active {
  background-color: #e8f4ff;
  color: #007bff;
}

.custom-date-range {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.date-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.date-input label {
  font-size: 12px;
  margin-bottom: 5px;
  color: #666;
}

.date-input input {
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
}

.apply-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
}

.apply-button:hover {
  background-color: #0056b3;
}

.complieaze-logo .logo {
  font-size: 24px;
  font-weight: bold;
}

.complieaze-logo .titlee {
  font-size: 14px;
  color: #666;
  margin-top: 5px;
}

.profilemenu{
    flex: 1;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1rem;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.error {
  color: red;
  margin: 20px;
  text-align: center;
}

.options-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.options-loader span {
  margin-left: 10px;
  font-size: 14px;
  color: #555;
}

.audit-loader-container {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh; 
  display: flex;
  z-index: 9999;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9); 
}

.audit-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.audit-loader-circle {
  animation: spin 2s linear infinite;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top-color: #438ed8;
  height: 50px;
  width: 50px;
  margin-bottom: 8px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}