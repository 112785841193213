@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-ExtraLight.ttf') format('truetype');
  font-weight: 200; 
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-Light.ttf') format('truetype');
  font-weight: 300; 
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: 400; 
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-SemiBold.ttf') format('truetype');
  font-weight: 600; 
  font-style: normal;
}

@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-Bold.ttf') format('truetype');
  font-weight: 700; 
  font-style: normal;
}


@font-face {
  font-family: 'Manrope';
  src: url('./fonts/Manrope-ExtraBold.ttf') format('truetype');
  font-weight: 800; 
  font-style: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* height: 90vh; */
  background: #F2F6FF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}